$(document).on('change', '.test-update-select', function(event) {
  let row = event.target.closest('tr');
  var state = event.target.value
  var jobId = event.target.dataset.jobId
  var lineId = event.target.dataset.lineId

  var url = "/jobs/"+jobId+"/tests/"+lineId+"/assign_task"

  if (event.target.options[event.target.selectedIndex].text.toUpperCase() === 'PASSED') {
    row.classList.remove('has-background-danger-light');
    row.classList.add('has-background-success-light');
  } else {
    row.classList.remove('has-background-success-light');
    row.classList.add('has-background-danger-light');
  }



  var states = ['Failed', 'Reterminate', 'Repull', 'Tone', 'Marginal']
  var selected_state = this.selectedOptions[0].text
  if (states.includes(selected_state)) {
    $('#modal-detail').addClass('is-active')
    var cable_no = row.children[0].textContent
    var modal_element = $('#modal-detail')[0].children[1]
    modal_element.children[1].children[0].lastElementChild.textContent = cable_no
    modal_element.children[1].children[1].lastElementChild.textContent = selected_state
    $('.save-more-detail-test')[0].dataset.lineId = lineId
    $('.save-more-detail-test')[0].dataset.state = state
  }else {
    processData(url, state)
  }
});

$(document).on('click', '.save-more-detail-test', function(event) {
  var description = $('#reason').val()
  var jobId = this.dataset.jobId
  var lineId = this.dataset.lineId
  var state = this.dataset.state
  var url = "/jobs/"+jobId+"/tests/"+lineId+"/assign_task"
  processData(url, state, description)
  $('#modal-detail').removeClass('is-active')
});

function processData(url, state, description) {
  $.ajax({
    type: "POST",
    url: url,
    data: {user_id: $('.user_line_select').val(), state: state, description: description},
    dataType: 'script'
  });
}
