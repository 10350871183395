function find_rect(rects, cable_no) {
  var rect_data = _.find(rects, function(rect) {
                var starting_no = $(rect).attr('starting')

                if (starting_no != undefined) {
                  if (starting_no.indexOf("-") == 1){
                    var arr_start_no = starting_no.split('-')
                    var parse_start = parseInt(arr_start_no[arr_start_no.length - 1])
                  }else{
                    var start_string = starting_no.replace(/\d/g,'')
                    var parse_start = starting_no.replace( /^\D+/g, '')
                  }
                }

                var ending_no = $(rect).attr('ending')
                if (ending_no != undefined) {
                  if (ending_no.indexOf("-") == 1){
                    var arr_end_no = ending_no.split('-')
                    var parse_end = parseInt(arr_end_no[arr_end_no.length - 1])
                  }else{
                    var start_string = ending_no.replace(/\d/g,'')
                    var parse_end = ending_no.replace( /^\D+/g, '')
                  }
                }

                if (cable_no.indexOf("-") == 1){
                  var arr_cable_no = cable_no.split('-')
                  var parse_cable = parseInt(arr_cable_no[arr_cable_no.length - 1])
                }else{
                  var cable_string = cable_no.replace(/\d/g,'')
                  var parse_cable = cable_no.replace( /^\D+/g, '')
                }

                if (parse_start != undefined || parse_end != undefined) {
                  if ( parse_end != undefined) {
                    if (parse_start <= parse_cable && parse_cable <= parse_end) {
                      if (start_string != undefined && cable_string != undefined){
                        if(start_string == cable_string){
                          return rect
                        }
                      }
                      if (arr_cable_no != undefined && arr_start_no != undefined) {
                        if (start_string == cable_string && arr_cable_no[0] == arr_start_no[0]){
                          return rect
                        }
                      }
                    }else {
                      return null
                    }
                  }else {
                    if (arr_cable_no != undefined && arr_start_no != undefined) {
                      if (arr_cable_no[0] == arr_start_no[0] && arr_cable_no[1] == arr_start_no[1]){
                        return rect
                      }
                    }else {
                      if ( start_string == cable_string && parse_start == parse_cable){
                        return rect
                      }
                    }
                    if(start_string == cable_string && parse_start == parse_cable){
                      return rect
                    }
                  }
                }
            });
  return rect_data
}

function commonLineOperation(that) {
  if (that.textContent == "NO") {
    that.textContent = "50%"
    that.classList.remove('has-text-danger')
    that.classList.add('has-text-warning')
  } else if (that.textContent == "50%") {
    that.textContent = "YES"
    that.classList.add('has-text-success')
    that.classList.remove('has-text-warning')
  } else {
    that.textContent = "NO"
    that.classList.add('has-text-danger')
    that.classList.remove('has-text-success')
  }

  var thatRow = that.parentElement.parentElement
  var cable_statuses = $(thatRow).find('.change-status')
  var status = 0
  for(let s of cable_statuses){
    if ($(s.children).hasClass('has-text-success')){
      status = status + 1
    }
  }
  if (status == cable_statuses.length){
    that.parentElement.parentElement.className = "has-background-success-light"
  }else{
    that.parentElement.parentElement.className = "has-background-danger-light"
  }
}

function mobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

var debounceDuration = mobileDevice() ? 280 : 500;

$(document).on('click', '.change-status > button.toggle_line_item', function () {
  // Replace the parameters with the actual data attributes from your button
  var parentElement = $(this).closest('tr');
  var buttons = parentElement.find('.change-status > button');
  var clickedId = $(this).data("state-value-id");

  var btnEl = $('.change-status > button[data-state-value-id="' + clickedId + '"]')
  var stateValueId = btnEl.data('state-value-id');

  var previousDebounce = btnEl.data('debouncedProcessData');
  if (previousDebounce) {
    previousDebounce.cancel();
  }

  if (clickedId !== stateValueId) {
    return
  }

  
    // Create and assign a new debounce function
  var newDebounce = _.debounce(processData, debounceDuration);
  btnEl.data('debouncedProcessData', newDebounce);

  commonLineOperation(btnEl[0]);
  var jobId = $('#job_id').val();
  var lineId = btnEl.data('id');
  var field =  btnEl.data('field');
  var cable_no = btnEl.data('cable-no');
  var textContent = btnEl.text();
  var fd = new FormData();
  fd.append('field', field);
  fd.append('text_content', textContent);

  newDebounce(jobId, stateValueId, lineId, cable_no, fd)
});

$(document).on('click', '.change-status-view-plan', function() {
  if (this.firstElementChild.textContent == "NO") {
    this.firstElementChild.textContent = "50%"
    this.firstElementChild.classList.remove('has-text-danger')
    this.firstElementChild.classList.add('has-text-warning')
  } else if (this.firstElementChild.textContent == "YES") {
    this.firstElementChild.textContent = "NO"
    this.firstElementChild.classList.add('has-text-danger')
    this.firstElementChild.classList.remove('has-text-success')
  } else {
    this.firstElementChild.textContent = "YES"
    this.firstElementChild.classList.add('has-text-success')
    this.firstElementChild.classList.remove('has-text-warning')
  }
  var jobId = $('#job_id').val();
  var lineId = this.firstChild.getAttribute('data-id')
  var stateValueId = this.firstChild.getAttribute('data-state-value-id')
  var field =  this.firstChild.getAttribute('data-field')
  var cable_no = this.firstChild.getAttribute('data-cable-no')
  var textContent = this.firstElementChild.textContent
  var fd = new FormData();
  fd.append('field', field);
  fd.append('text_content', textContent)
  // $('.toggle_line_item').prop('disabled', false)
  processData(jobId, stateValueId, lineId, cable_no, fd)
});

const dbName = 'cableman';
const dbVersion = 2
const dbStoreName = 'lineToggle'

async function processData(jobId, stateValueId, lineId, cable_no, fd) {
  const plainFormData = Object.fromEntries(fd.entries());

  const formDataJsonString = JSON.stringify(plainFormData);
  
  const url = `/jobs/${jobId}/lines/${lineId}/state_values/${stateValueId}`
  const fetchOptions = {
    method: "PATCH",
    body: fd,
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  };

  const response = await fetch(url, fetchOptions).catch(() => {
                            
                            indexdblineDataCreate(url, jobId, stateValueId, lineId, formDataJsonString)
                         })

  if (!response.ok) {
    const errorMessage = await response.text();
    
  } else {
    const res = await response.json();
    deleteLineDataFromIndexdb(dbStoreName, url);
    updateSvgUi(res, cable_no)
    
  }
}

async function deleteLineDataFromIndexdb(dbStore, ID) {
  let request = await self.indexedDB.open(dbName, dbVersion);
  request.onsuccess = function(event) {
    var transaction = this.result.transaction(dbStore, 'readwrite');
    var collection = transaction.objectStore(dbStore);
    let reqs = collection.delete(ID);
    transaction.complete;
    reqs.onsuccess = function() {
      // storeDynamicJos(cabinet)
      
    };
    reqs.onerror = function() {
      
    };
  }
}

async function indexdblineDataCreate(url, jobId, stateValueId, lineId, formData) {
  var fd = JSON.parse(formData)
  const data = {url: url, jobId: jobId, stateValueId: stateValueId, lineId: lineId, formData: {field: fd.field}}
  let request = await self.indexedDB.open(dbName, dbVersion);
  request.onsuccess = function(event) {
    
    
    var transaction = this.result.transaction(dbStoreName, 'readwrite');
    var collection = transaction.objectStore(dbStoreName);
    let reqs = collection.put(data);
    transaction.complete;
    reqs.onsuccess = function() {
      // storeDynamicJos(cabinet)
      
      initLineBgroundSync()
    };

    reqs.onerror = function() {
      
    };
  }
}

async function initLineBgroundSync() {
  if ('serviceWorker' in navigator && 'SyncManager' in window) {
    navigator.serviceWorker.ready
      .then(function(sw) {
        return sw.sync.register('sync-line-toggle');
      });
  }
}

function updateSvgUi(res, cable_no) {
  
  var svg = $('.svg-container').find('svg')

  var rects = svg.children().find('rect')
  var rect = find_rect(rects, cable_no)
  
  if (rect != undefined) {
    rect.style.stroke = res.stroke_color
  }
}