import consumer from "./consumer"

consumer.subscriptions.create({ channel: "TaskChannel" }, {
  connected() {
    
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if (data.destroy) {
      $('table#task tbody tr#'+data.id).remove();
    }else{
      if (data.new) {
        var tr = $('<tr/>', {id: data.id});
        Object.entries(data).forEach(function([key, value]) {
          if( key !='id' && key != 'new' ){
            var td = $('<td/>', { class: key}).text(value);
            tr.append(td);
          }
        });
        tr.append($('<td>').append($('<a>', {href: '/admin/tasks/'+data.id}).text('Show')));
        tr.append($('<td>').append($('<a>', {href: '/admin/tasks/'+data.id+'/edit'}).text('Edit')));
        tr.append($('<td>').append($('<a>', {href: '/admin/tasks/'+data.id, rel: 'nofollow'}).attr({'data-method': 'delete', 'data-confirm': 'Are you sure?'}).text('Destroy')));

        $('table#task tbody').append(tr);
      }else{
        var row = $('table#task tr#'+data.id);
        row.children().each(function(index, el) {
          var key = $(el).attr('class');
          $(el).text(data[key]);
        });
      }
    }
  },
});
