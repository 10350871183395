import Sortable from 'sortablejs';

var map;
var poly;

$(document).on('cocoon:after-remove', '#route_details', function(e, task) {
  updatePosition();
  updateDistance();
  drawPolyLine();
  updateDayToNextProject();
});

function initSortable() {
  var el = $("#route_details")[0];
  var sortable = new Sortable(el, {
    animation: 150,
    ghostClass: 'blue-background-class',
    
    // We iterate each route detail on add/update/delete of route detail from 
    // the route and update there position into the hidden field    
    onSort: function (e) {
      updatePosition();
      updateDistance();
      drawPolyLine();
      updateDayToNextProject();
    },
  });
}

function initMap() {
  map = new google.maps.Map(document.getElementById("map"), {
    zoom: 12,
    center: { lat: 39.0119, lng: 98.4842 },
    gestureHandling: 'greedy'
  });

  var bounds = new google.maps.LatLngBounds();
  gon.markers.forEach(function (_a, i) {
    var jobId = _a[1].job_id;

    var marker = markerObj(_a);
    var infoWindow = infoWindowObj(_a[1]);
    poly = new google.maps.Polyline({
      strokeColor: '#276fe4',
    });
    poly.setMap(map);
    
    marker.addListener("click", function () {
      infoWindow.close();
      infoWindow.open(marker.getMap(), marker);
      
      AddJobToRoute(jobId);
      updatePosition();
    });
    
    bounds.extend(marker.getPosition());
  });

  map.fitBounds(bounds);
}

function markerIconImage(jobStatus) {
  var baseUrl = 'http://maps.google.com/mapfiles/ms/icons/';
  var icon = 'orange-dot.png';

  switch (jobStatus) {
    case 'incoming':
      icon = "blue-dot.png";
      break;
    case 'on_hold':
      icon = "red-dot.png";
      break;
    case 'in_progress':
      icon = "yellow-dot.png";
      break;
    case 'tentative':
      icon = "purple-dot.png";
      break;
  }
  return baseUrl + icon;
}

function markerObj(_a) {
  var position = _a[0];
  var jobName = _a[1].job_name;
  var jobStatus = _a[1].job_status;
  
  return new google.maps.Marker({
          position: position,
          map: map,
          title: jobName,
          icon: markerIconImage(jobStatus),
          optimized: false,
        });
}

function infoWindowObj(jobDetails) {
  var jobName = jobDetails.job_name;
  var jobId = jobDetails.job_id;
  var jobStartEndDate = jobDetails.job_start_end_date
  var jobDuration = jobDetails.job_duration
  
  var content = '<div><strong> '+jobName+'</strong></div>' + 
                '<div class="mt-1"><strong> '+jobStartEndDate+'</strong></div>' +
                '<div class="mt-1 is-flex is-justify-content-space-between"> <strong>'+ jobDuration +'</strong>' +
                '<a href="javascript:showJobDetail('+ jobId +')" class="has-text-weight-bold is-underlined">View Details</a> </div>'

  return new google.maps.InfoWindow({content: content});
}

function showJobDetail(jobId) {
  $.ajax({ 
    url: '/jobs/' + jobId + '/edit_schedule',
    data: { action_button: false }
  });
}

function AddJobToRoute(jobId) {
  var routeId = $('.select_route').val();

  if (routeId == '' || routeId == undefined) {
    alert('Please select route first');
    return
  }

  // This will trigger add fields button of coccon gem to add the 
  // new route details into the route right side window.
  $(".add_fields")[0].click();

  $.ajax({
    url: '/routes/'+routeId+'/route_details/new',
    data: { job_id: jobId }
  }).always(function() {
    updateDistance();
    addPointToPolyLine();
    updateDayToNextProject();
  });
}

async function getDistance(origin, destination, destinationEle) {
  var destinationEle = destinationEle;
  var service = new google.maps.DistanceMatrixService();
  var response = await service.getDistanceMatrix(
    {
      origins: [origin],
      destinations: [destination],
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      travelMode: 'DRIVING', // There is no flying option into the g map for now that why we are passing it as hard coded.
    });
  // debugger
  var distance = response.rows[0].elements[0].distance.value;
  var driveTime = response.rows[0].elements[0].duration.value;
  setDistanceInForm(distance, destinationEle);
  setDriveTimeInForm(driveTime, destinationEle);
}

function setDistanceInForm(distance, destinationEle) {
  var distanceInMiles = (distance / 1609).toFixed(1);
  
  $(destinationEle).find('.project_miles_text')[0].innerText = distanceInMiles + ' miles';
  $(destinationEle).find('.route_detail_miles').val(distance);
}

function setDriveTimeInForm(driveTime, destinationEle) {
  var hours = Math.floor(driveTime / 3600);
  var minutes = Math.floor(driveTime / 60 % 60);

  $(destinationEle).find('.project_drive_time_text')[0].innerText = hours + ' H : ' + minutes + 'M';
  $(destinationEle).find('.route_detail_drive_time').val(driveTime);
}

function getAllNestedFields() {
  return $("#route_details").children('.nested-fields:visible');
}

function updatePosition() {
  getAllNestedFields().each(function(index) {
    $(this).find('.route_detail_position').val(index);
  });
}

function updateDistance() {
  for (var i = 0; i < getAllNestedFields().length -1 ; i++) {
    var firstEle = getAllNestedFields()[i];
    var secondEle = getAllNestedFields()[i+1];
    
    var origin = new google.maps.LatLng($(firstEle).find('.project_lat').val(), $(firstEle).find('.project_long').val());
    var destination = new google.maps.LatLng($(secondEle).find('.project_lat').val(), $(secondEle).find('.project_long').val());
    getDistance(origin, destination, firstEle);
  }
  setDistanceInForm(0, getAllNestedFields().last());
  setDriveTimeInForm(0, getAllNestedFields().last());
}

function updateDayToNextProject() {
  for (var i = 0; i < getAllNestedFields().length -1 ; i++) {
    var firstEle = getAllNestedFields()[i];
    var secondEle = getAllNestedFields()[i+1];
    var lastWorkingDate = $(firstEle).find('.project_last_working_date').val();
    var firstWorkingDate = $(secondEle).find('.project_first_working_date').val();
    var dayDifference = getDayDifference(firstWorkingDate, lastWorkingDate);
    setDayToNextProjectInForm(dayDifference, firstEle);
  }
  setDayToNextProjectInForm(0, getAllNestedFields().last());
}

function setDayToNextProjectInForm(DayToNextProject, originEle) {
  $(originEle).find('.days_to_next_project')[0].innerText = DayToNextProject;
}

function getDayDifference(firstWorkingDate, lastWorkingDate) {
  var firstWorkingDate = new Date(firstWorkingDate);
  var lastWorkingDate = new Date(lastWorkingDate);
  var diffTime = (firstWorkingDate - lastWorkingDate);
  
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
}

function addPointToPolyLine() {
  var size = getAllNestedFields().length;
  var lastEle = getAllNestedFields()[size - 1];
  
  var origin = new google.maps.LatLng($(lastEle).find('.project_lat').val(), $(lastEle).find('.project_long').val());

  var path = poly.getPath();
  path.push(origin);
}

function drawPolyLine() {
  poly.setPath([]);
  var path = poly.getPath();
  for (var i = 0; i < getAllNestedFields().length; i++) {
    var firstEle = getAllNestedFields()[i];
    var origin = new google.maps.LatLng($(firstEle).find('.project_lat').val(), $(firstEle).find('.project_long').val());
    path.push(origin);
  }
  poly.setMap(map);
}

$(document).on('change', '.select_route', function() {
  var id = this.value
  $.ajax({
    type: "GET",
    url: '/routes/'+id+'/edit'
  })
});

$(document).on('click', '.edit_route', function() {
  $(".route-submit-btn").prop('disabled', false);
})

$(document).on('click', '.route-submit-btn', function(e) {
  e.preventDefault();
  const form = $('#route-details-form');

  $.ajax({
    url: form.attr('action'),
    type: form.attr('method'),
    data: form.serialize(),
    dataType: 'script', // Expect a JS response
    success: function(response) {
      $(".route-submit-btn").prop('disabled', true);
      // You can handle any additional success logic here
      console.log("Form submitted successfully.");
    },
    error: function(response) {
      // Handle errors here
      console.log("Form submission failed.");
    }
  });
});

$(document).on('click', '.route_detail_btn', function() {
  var routeId = $('.select_route').val();
  
  if (routeId == '' || routeId == undefined) {
    alert('Please select route first');
    return
  }

  $.ajax({
    type: "GET",
    url: '/routes/'+routeId+'/route_details'
  })
})

$(document).on('change', '.project_status_checkbox', function() {
  var statusText = '&status%5B%5D=';
  var search = '';

  $('.project_status_checkbox:checked').each(function(index) {
    search = search + statusText + this.name;
  })

  window.location.search = '?'+search;
})

$(document).on('click', '.project_date', function() {
  var jobId = this.parentElement.parentElement.parentElement.dataset.job_id
  $.ajax({
    type: "GET",
    url: '/admin/jobs/'+jobId+'/edit_working_days'
  })
})


window.initMap = initMap;
window.initSortable = initSortable;
window.drawPolyLine = drawPolyLine;
window.showJobDetail = showJobDetail;
window.updateDayToNextProject = updateDayToNextProject;