import Calendar from 'tui-calendar'; /* ES6 */
import "tui-calendar/dist/tui-calendar.css";

// If you use the default popups, use this.
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('calendar') != null) {
    var cal = new Calendar('#calendar', {
      defaultView: 'month',
      template: {
        monthDayname: function(dayname) {
          return '<span class="calendar-week-dayname-name">' + dayname.label + '</span>';
        }
      }
    });

    cal.createSchedules(gon.scheduled_jobs);

    $(document).on('click', '.move-today', function() {
      cal.today();
      var month_name = cal.getDate()._date.toLocaleString('default', { month: 'long' })
      var full_year = cal.getDate().getFullYear()
      $('.renderRange').text(month_name + " "+ full_year)
    });

    $(document).on('click', '.move-day', function() {
      if (this.dataset.action == 'move-prev'){
        cal.prev();
        var month_name = cal.getDate()._date.toLocaleString('default', { month: 'long' })
        var full_year = cal.getDate().getFullYear()
        $('.renderRange').text(month_name + " "+ full_year)
      }
      else{
        cal.next();
        var month_name = cal.getDate()._date.toLocaleString('default', { month: 'long' })
        var full_year = cal.getDate().getFullYear()
        $('.renderRange').text(month_name + " "+ full_year)
      }
    });

    var month_name = cal.getDate()._date.toLocaleString('default', { month: 'long' })
    var full_year = cal.getDate().getFullYear()
    $('.renderRange').text(month_name + " "+ full_year)
  }
});
