document.addEventListener('turbolinks:load', () => {
  initDatePicker();

  $('#operating_hours').datepicker({
    multidate: true,
    format: 'dd/mm/yyyy',
    todayBtn: 'linked',
    todayHighlight: true
  })

  $('.input-daterange, .filter-datepicker').datepicker({
    format: 'mm/dd/yyyy'
  })

  $(".report_start_date").datepicker("setDate", $('.report_start_date').val())
  $("#timein").val(new Date().toLocaleTimeString());
});

$( document ).ready(function() {
  $(".report_start_date").datepicker("setDate", $('.report_start_date').val())
  // $('#working_days').datepicker( "show" );
  $("#timein").val(new Date().toLocaleTimeString());
});

function initDatePicker() {
  $('#working_days').datepicker({
    multidate: true,
    format: 'dd/mm/yyyy',
    todayBtn: 'linked',
    todayHighlight: true
  })
}

window.initDatePicker = initDatePicker;
