document.addEventListener('turbolinks:load', () => {
  $("#report_type").on('change', function() {
    setFormUrl();
    fetchUserJobData();
  })

  $(".date_range").on('change', function() {
    fetchUserJobData();
  })

  function setFormUrl() {
    var url;
    var reportType = getReportTypeValue();

    if (reportType == 'Expense Reports') {
      url = '/expenses/report'
    } else if(reportType == 'Daily Reports')  {
      url = '/daily_reports/report'
    } else if (reportType == 'Man Hour Reports') {
      url = '/time_trackings/report'
    } else {
      url = '/time_trackings/show'
    }

    $(".reports_filter_form")[0].action = url;
  }

  function fetchUserJobData() {
    var reportType = getReportTypeValue();

    if (reportType == '') {
      return true
    }

    url = getUserAndJobUrl();
    $.ajax({
      type: "GET",
      url: url,
      data: {
        'by_period[start_date]': $('#start_date')[0].value,
        'by_period[end_date]': $('#end_date')[0].value,
      },
      success: function(data) {
        $("#by_user").html('<option value="">All users</option>'+data.users)
        $("#by_job").html('<option value="">All projects</option>'+data.jobs)
      }
    })
  }

  function getUserAndJobUrl() {
    var url
    var reportType = getReportTypeValue();

    if (reportType == 'Expense Reports') {
      url = 'expenses/users_and_jobs'
    } else if (reportType == 'Daily Reports') {
      url = 'daily_reports/users_and_jobs'
    } else if (reportType == 'Man Hour Reports'){
      url = 'time_trackings/users_and_jobs'
    } else {
      url = '/time_trackings/show'
    }
    return url
  }
  
  function getReportTypeValue() {
    return $("#report_type").val();
  }

  $(".reports_filter_form").on('submit',function (e) {
    if (getReportTypeValue() == '') {
      e.preventDefault();
      alert('Please select report type first');
      return  false;
    }
  });
});
