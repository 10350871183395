import consumer from "./consumer"

consumer.subscriptions.create("CompletionChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    
    for (var obj of data) {
        if (obj.type === 'job') {
          jobRealTime(obj)
        } else if (obj.type === 'cabinet') {
          cabinetRealTime(obj)
        }
    }
  }
});

function jobRealTime(job) {
  var progress_bar_container = $(`.progress-bar-div-${job.id}`)
  var test_progress_div = $(`.job-progress-${job.id}`)

  if (progress_bar_container.length > 0) {
    var progress_bar = progress_bar_container.children()[0].children
    var progress_bar_text = progress_bar_container.children()[1]
    $(progress_bar).val(job.value)
    progress_bar_text.innerText = job.value + "%"
  } else if (test_progress_div.length > 0) {
    test_progress_div.val(job.value)
    $(`.job-progress-status-${job.id}`).text(`completed ${job.value}%`)
    if (job.value == 100) {
      setTimeout(
        function() {
         test_progress_div.parent().addClass('is-hidden')
      }, 1000);
    }
  }
}

function cabinetRealTime(cabinet) {
  
  var cabinet_percentage = $(`.cabinet-percentage-${cabinet.id}`)
  cabinet_percentage.text(`${cabinet.value}% Complete`)
}