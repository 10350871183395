import consumer from "./consumer"
// import CableReady from 'cable_ready'

// consumer.subscriptions.create("ProgressBarChannel", {

consumer.subscriptions.create("ProgressBarChannel", {
  connected() {
    let job_id = $('#testingJob').attr('data-job-id')
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },


  received(data) {
    if (data) {
      if ($('.progress').hasClass('fluke-progress1')) {
        $('.fluke-progress1')[0].value = data.value
        $('.progress_heading')[0].innerText = data.value + "%"
      }else if ($('.fluke-progress').length > 0) {
        $('.fluke-progress')[0].value = data.value
        $('.progress-status')[0].innerText = "completed " + data.value + "%"
        if (data.value == 100) {
          setTimeout(
            function() {
             $('.fluke-progress').parent().addClass('is-hidden')
          }, 1000);
        }
      }
    }
  }
})