// Toggle navbar burger when in mobile view
// https://bulma.io/documentation/components/navbar/#navbar-menu
document.addEventListener('turbolinks:load', () => {

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

        // Add a click event on each of them
        $navbarBurgers.forEach( el => {
            el.addEventListener('click', () => {

                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = document.getElementById(target);

                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');

            });
        });
    }

});

// Delete messages/notifications
// https://bulma.io/documentation/elements/notification/#javascript-example
document.addEventListener('turbolinks:load', () => {
    (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
        var $notification = $delete.parentNode;

        $delete.addEventListener('click', () => {
            $notification.parentNode.removeChild($notification);
        });
    });
});

// Fix that annoying misbehaviour of checkboxes not marking themselves as
// checked/unchecked between reloads
document.addEventListener('turbolinks:load', () => {
    const checkboxes = document.querySelectorAll('.checkbox');

    // Add a click event on each of them
    checkboxes.forEach( el => {
        const value = el.value;

        if (value == "true") {
            el.checked = true;
        } else {
            el.checked = false;
        }
    });
})
