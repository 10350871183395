$(document).on('change', '.panel-terminated-dropdown', function(e) {
  id = e.currentTarget.dataset.panelId;
  $($('.terminated-submit-btn-'+id)[0]).click();
});

$(document).on('change', '.rack-elevation-file-upload', function() {
  $($(".rack-elevation-submit-btn")[0]).click();
});

$(document).on('click', '.save-location-detail', function() {
  $(".location-detail-form").submit();
});
