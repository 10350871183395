import consumer from "./consumer"

document.addEventListener("DOMContentLoaded", () => {
  let progressBarContainers = {}; // Object to store progress bar containers by user ID

  const createProgressBar = (userId) => {
    const containerId = `progress-bar-container-${userId}`;

    // Check if progress bar container for this user already exists
    if (progressBarContainers[userId]) {
      return; // Don't create a new progress bar if one already exists for this user
    }

    const progressBarContainer = document.createElement('div');
    progressBarContainer.id = containerId;
    progressBarContainer.classList.add('window-container');
    progressBarContainer.classList.add('is-hidden');
    
    progressBarContainer.innerHTML = `
      <div id="global-progress-bar" class="progress-bar-div">
        <div class="progress-bar-align">
          <progress id="progress-bar" class="progress is-success" value="0" max="100"></progress>
        </div>
        <div class="progress-bar-hide">
          <button id="hide-progress-bar-btn" class="delete"></button>
        </div>
      </div>
    `;

    let isDragging = false;
    let offsetX, offsetY;
    let isScrolling = false;

    const startDrag = (e) => {
      if (!isScrolling && e.target === progressBarContainer) {
        isDragging = true;
        offsetX = e.clientX || e.touches[0].clientX - progressBarContainer.getBoundingClientRect().left;
        offsetY = e.clientY || e.touches[0].clientY - progressBarContainer.getBoundingClientRect().top;
        
        if (e.type === 'touchstart') {
          e.preventDefault(); // Prevent scrolling while dragging on touch devices
        }
      }
    };

    const drag = (e) => {
      if (isDragging) {
        const left = (e.clientX || e.touches[0].clientX) - offsetX;
        const top = (e.clientY || e.touches[0].clientY) - offsetY;
        progressBarContainer.style.left = `${left}px`;
        progressBarContainer.style.top = `${top}px`;
      }
    };

    const stopDrag = () => {
      isDragging = false;
    };

    progressBarContainer.addEventListener('mousedown', startDrag);
    progressBarContainer.addEventListener('touchstart', startDrag);
    document.addEventListener('mousemove', drag);
    document.addEventListener('touchmove', drag);
    document.addEventListener('mouseup', stopDrag);
    document.addEventListener('touchend', stopDrag);

    // Detect scrolling
    document.addEventListener('scroll', () => {
      isScrolling = true;
      setTimeout(() => { isScrolling = false; }, 500); // Reset isScrolling after 500ms
    });
    
    document.body.appendChild(progressBarContainer);

    // Store the progress bar container in the object with user ID as key
    progressBarContainers[userId] = progressBarContainer;
  };

  const removeProgressBar = (userId) => {
    const progressBarContainer = progressBarContainers[userId];
    if (progressBarContainer) {
      progressBarContainer.remove();
      delete progressBarContainers[userId]; // Remove the progress bar container from the object
    }
  };

  const USER_ID = document.querySelector('#current_user_id')?.value;
  if (USER_ID) {
    const subscription = consumer.subscriptions.create(
      { channel: "FileDownloadProgressChannel", user_id: USER_ID },
      {
        connected() {
          console.log("Connected to FileDownloadProgressChannel");
          createProgressBar(USER_ID);
        },
        disconnected() {
          console.log("Disconnected from FileDownloadProgressChannel");
          removeProgressBar(USER_ID);
        },
        received(data) {
          if (data.progress) {
            // Update progress bar
            const progressBar = document.getElementById("progress-bar");
            const divt = document.getElementById(`progress-bar-container-${USER_ID}`);

            if (divt) {
              divt.classList.remove('is-hidden');
            }

            if (progressBar) {
              progressBar.value = data.progress;
            }
          } else if (data.complete) {
            const divt = document.getElementById(`progress-bar-container-${USER_ID}`);

            if (divt) {
              divt.classList.remove('is-hidden');
            }

            toastr.success("Download completed");
            console.log("Download completed");
            removeProgressBar(USER_ID);
            subscription.unsubscribe(); // Unsubscribe from the channel
          } else if (data.error) {
            const divt = document.getElementById(`progress-bar-container-${USER_ID}`);

            if (divt) {
              divt.classList.remove('is-hidden');
            }
            toastr.error(data.error);
            console.error("Error:", data.error);
            removeProgressBar(USER_ID);
            subscription.unsubscribe();
          }
        },
      }
    );
  }
});

