// LIBRARY PACKAGES ARE IMPORTED HERE.
import 'requirejs/require.js';
import 'select2/dist/js/select2';
import '@fortawesome/fontawesome-free/js/all';
import 'bootstrap-datepicker/js/bootstrap-datepicker';

import 'jquery-ui/ui/core.js';
import 'jquery-ui/ui/widgets/dialog.js';
import 'jquery-ui/ui/widgets/sortable';

