$(document).on('change', '.lunchtime', function() {
  userId = this.dataset.userId;
  calculate_hour()
});

$(document).on('change', '.timein', function() {
  userId = this.dataset.userId;
  calculate_hour()
});

$(document).on('change', '.timeout', function() {
  userId = this.dataset.userId;
  calculate_hour()
});

$(document).on('change', '.absent', function(event) {
  userId = this.dataset.userId;
  if (event.currentTarget.checked) {
    var absent = $('#absent-'+userId).val();
    absent = 0;
    $("#hours-"+userId).val(absent);
  } else {
    value2 = "";
    $("#hours-"+userId).val(value2);
  }
});

function calculate_hour(){
  var timein = $('#timein-'+userId).val();
  var timeout = $('#timeout-'+userId).val();
  var lunchtime = $('#lunchtime-'+userId).val();
  timein = timein.split(":");
  timeout = timeout.split(":");
  if (lunchtime == '15 minutes'){
    var durationInMinutes = 15;
    var MS_PER_MINUTE = 60000;
  }
  if (lunchtime == '30 minutes'){
    var durationInMinutes = 30;
    var MS_PER_MINUTE = 60000;
  }
  if (lunchtime == '1 hour'){
    var durationInMinutes = 60;
    var MS_PER_MINUTE = 60000;
  }
  var timeinDate = new Date(0, 0, 0, timein[0], timein[1], 0);
  var timeoutDate = new Date(0, 0, 0, timeout[0], timeout[1], 0);
  if (lunchtime == 'No Lunch'){
    var diff = (timeoutDate.getTime() - timeinDate.getTime())
  }else{
    var diff = (timeoutDate.getTime() - timeinDate.getTime()) - (durationInMinutes * MS_PER_MINUTE);
  }
  var hours = Math.floor(diff / 1000 / 60 / 60);
  diff -= hours * 1000 * 60 * 60;
  var minutes = Math.floor(diff / 1000 / 60);
  // If using time pickers with 24 hours format, add the below line get exact hours
  if (hours < 0)
     hours = hours + 24;

  value = (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
  if (value !== 'NaN:NaN') {
    $("#hours-"+userId).val(value);
  }
}