document.addEventListener('turbolinks:load', () => {
  function toggleButton(el) {
    if (el == null) { return; };
    // Get the target from the "data-target" attribute
    let cabinetId = el.dataset.cabinetId
    let jobId = el.dataset.jobId
    let pageType = el.dataset.pageType
    let detailType = el.dataset.detailType
    var tableContainer = $(`.cabinet-lines-wrapper-${jobId}-${cabinetId}`)

    if (tableContainer.length == 0) {
      tableContainer = $(`.cable-${detailType}-${jobId}-${cabinetId}`)
    }
    var linesLoaderDiv = $(`.lines-loader-${jobId}-${cabinetId}`)
    var searchQuery = $("#search_query").attr("data-search")
    // If the table is hidden
    var el = $(`.toggle-button-${jobId}-${cabinetId}`)[0]

    if (el.childNodes[0].classList) {
      if (el.childNodes[0].classList.contains("fa-angle-down")) {
        el.childNodes[0].classList = 'fas fa-lg fa-angle-up';
      } else {
        el.childNodes[0].classList = 'fas fa-lg fa-angle-down';
      }
    }


    if (tableContainer[0].classList.contains('is-hidden')) {
      linesLoaderDiv.addClass('linesSpinnerDiv')
      // el.childNodes[0].classList = 'fas fa-lg fa-angle-up';
      localStorage.setItem(el.id, true);
      if (pageType !== 'testing') {
        fetchCabinetLines(jobId, cabinetId, searchQuery)
      } else {
        tableContainer.removeClass('is-hidden');
      }
      // Mark the button as open (we'll toggle it after this block)
    } else {
      linesLoaderDiv.removeClass('linesSpinnerDiv')
      tableContainer.addClass('is-hidden');
      // el.childNodes[0].classList = 'fas fa-lg fa-angle-down';
      localStorage.setItem(el.id, false);
    }
  }

  function fetchCabinetLines(jobId, cabinetId, searchQuery) {
    // $(`cabinet-lines-wrapper-${jobId}-${cabinetId}`)
    var url = `/jobs/${jobId}.js?cabinet_id=${cabinetId}`
    if (searchQuery) {
      url = `${url}.js&search=${searchQuery}`
    }

    $.ajax({
      type: "GET",
      url: url,
      cache: true
    })
  }

  // Get all "toggle-cabinet" elements
  const toggle_buttons = document.querySelectorAll('.toggle-cabinet');
  var searchQuery = $("#search_query").attr("data-search")

  // Add a click event on each of them
  toggle_buttons.forEach( el => {
    el.addEventListener('click', (event) => {
      // https://www.thegolfmentor.com/tech_blog/turbolinks_battle
      event.preventDefault();
      event.stopImmediatePropagation();
      

      toggleButton(el);
    });
    if (searchQuery) {
      el.click()
    }
  });
});

$(document).on('click', '.toggle-line-details', function() {
  var target = $(this).data('target')
  if ($(`#${target}`).hasClass("is-hidden")) {
    $(`#${target}`).removeClass('is-hidden')
  } else {
    $(`#${target}`).addClass('is-hidden')
  }
});