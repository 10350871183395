// Modals

var rootEl = document.documentElement;
var $modalButtons = getAll('.modal-button');
var $modalCloses = getAll('.modal-close, .modal-card-head .delete, .modal-card-foot .button');

if ($modalButtons.length > 0) {
  $modalButtons.forEach(function ($el) {
    $el.addEventListener('click', function () {
      var target = $el.dataset.target;
      openModal(target);
    });
  });
}

if ($modalCloses.length > 0) {
  $modalCloses.forEach(function ($el) {
    $el.addEventListener('click', function () {
      closeModals();
    });
  });
}

openModal = function(target) {
  var $target = document.getElementById(target);
  // rootEl.classList.add('is-clipped');
  $target.classList.add('is-active');
}

closeModals = function() {
  var $modals = document.querySelectorAll('.modal');
  rootEl.classList.remove('is-clipped');
  $modals.forEach(function ($el) {
    $el.classList.remove('is-active');
    // $el.parentElement.innerHTML = '';
  });
}

closeNotificationModal = function() {
  var $modals = document.querySelectorAll('.notification-modal');
  rootEl.classList.remove('is-clipped');
  $modals.forEach(function ($el) {
    $el.classList.remove('is-active');
  });
}

document.addEventListener('keydown', function (event) {
  var e = event || window.event;

  if (e.keyCode === 27) {
    closeModals();
    closeDropdowns();
  }
});

document.addEventListener('click', function(event) {
  target = event.target;
  if (target.classList.contains('delete') || target.classList.contains('modal-close'))
    if (target.classList.contains('notification-delete'))
      closeNotificationModal();
    else
      closeModals();
});
// Utils

function getAll(selector) {
  var parent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document;

  return Array.prototype.slice.call(parent.querySelectorAll(selector), 0);
}