// Get all "dropdowns" elements
document.addEventListener('turbolinks:load', () => {
  var selectInput = document.querySelectorAll('.select-modal-input');
  selectInput.forEach( (el, i) => {
    var cabinetID = el.dataset.cabinetId
    $("#select-tech-"+cabinetID).select2({
      width: '100%'
    });
  })

  var selectInput = document.querySelectorAll('.select-modal-printer-input');
  selectInput.forEach( (el, i) => {
    var cabinetID = el.dataset.cabinetId
    $("#select-printer-"+cabinetID).select2({
      width: '100%'
    });
  });
});
