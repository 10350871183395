$( document ).ready(function() {
  // $('body').on('change', '.clock_in_clock_out_job, .time_tracking_date_picker', function() {
  //   timeTrackingDate = $(".time_tracking_date_picker").val();
  //   jobId = $(".clock_in_clock_out_job").val();

  //   get_work_hours(jobId);      
  // });

  function get_work_hours(jobId) {
    timeTrackingDate = $(".time_tracking_date_picker").val();

    if(jobId != '') {
      $.get('/jobs/'+ jobId +'/work_hours', { time_tracking_date: timeTrackingDate });
    }
  }

  window.get_work_hours = get_work_hours;
});

$(document).on("click","#time_tracking_multiple_location",function() {
  $('#time_tracking_clock_in').removeClass('disabled')
  $('#time_tracking_clock_out').removeClass('disabled')
});

$(document).on("click",".add-travel-btn",function() {
  $('#travel-btn').addClass('is-hidden')
});

$(document).on("change", "#time_tracking_date", function() {
  $('#time_tracking_clock_in').removeClass('disabled')
  $('#time_tracking_clock_out').removeClass('disabled')


  // let selectedDate = $(this).val();
  // $.ajax({
  //   url: '/time_trackings/time_tracking_jobs_for_date',
  //   method: 'GET',
  //   dataType: 'script',
  //   data: { date: selectedDate }
  // });
});

$(document).on("click",".update-time-tracking",function() {
  var hour = $("#time_tracking_travel_times_attributes_0_time_4i").val();
  var min = $("#time_tracking_travel_times_attributes_0_time_5i").val();
  if (min != "") {
    if (hour == "") {
      alert('Please select value of hour or add 00 value');
      return  false;
    }
  }
  if (hour != ""){
    if (min == "") {
      alert('Please select value of minute or add 00 value');
      return  false;
    }
  }
});

$(document).on("change","#time_card_by_user",function() {
  var id = this.value
  $('#time_tracking_job_id').removeClass('disabled');
  $.ajax({
    type: "GET",
    url: '/time_trackings/users_and_jobs',
    dataType: 'json',
    data: {
      user_id: id
    },
    success: function(data) {
      $("#time_tracking_job_id").html('<option value="">Select Job</option>'+data.jobs)
    }
  })
});

$(document).on("change", ".time_tracking_date_picker", function() {
  var selectedDate = $(this).val(); // Get the selected date
  $.ajax({
    url: '/time_trackings/job_cards',
    type: 'GET',
    dataType: 'script',
    data: { date: selectedDate } // Send the selected date as a parameter
  });
});