// https://www.w3schools.com/howto/howto_js_filter_table.asp
// Get all "toggle-cabinet" elements

// Filter Table result for given type value using debounce concept.
// Global variable containing time to be out for given searching and Last value searched.

var timer, value;
// Search used on
// $(document).on('input', '#search', function() {
//   // Typed Value in Search Field.
//   var filter_txt = $(this).val();

//   // Display result of last time searched value.
//   localStorage.setItem(this.id, filter_txt);
//   autoCompleteLines()

//   clearTimeout(timer);  // Clear Time if time Function is called in given time interval.
//   if ((filter_txt != '' && filter_txt) && value != filter_txt){
//     // Check User typing completes and stop typing for 1 second.
//     timer = setTimeout(function() {
//       value = filter_txt;
//       getSearchedCable(filter_txt.toLowerCase());
//     }, 800);
//   }else{
//     count = $('tbody tr').length;
//     $('.table').removeClass('is-hidden');
//     $('.table tbody tr').removeClass('is-hidden');
//     $('#search').parent().next().children().text('Total '+ count);
//   }
// });


document.addEventListener('turbolinks:load', () => {
  
  selectizeContainer()

  async function searchCabinetLines(jobId, cabinetId, searchQuery) {
    var url = `/jobs/${jobId}?type=collections_search&cabinet_id=${cabinetId}`
    if (searchQuery) {
      url = `${url}&search=${searchQuery}`
    }

    $.ajax({
      type: "GET",
      url: url,
      dataType: 'script'
    })
  }
})

var selectizeContainer = function() {
  $('#search').selectize({
    maxItems: 1,
    persist: true,
    refreshOptions: true,
    openOnFocus: false,
    create: true,
    hideSelected: true,
    valueField: 'cable',
    labelField: 'cable',
    searchField: ['cable', 'device', 'notes', 'area', 'switch_port'],
    load: function(query, callback) {
      var jobId = this.$input.data("job-id")
      
      $.ajax({
        url: `/admin/jobs/${jobId}/lines/autocomplete?search=${query}`,
        dataType: "json",
        type: 'GET',
        error: function(res){
          callback();
        },
        success: function(res) {
          
          callback(res.slice(0,10));
        }
      })
    },
    onChange: function(query) {
      if (!query.length) {
        const url = new URL(location.href);
        location.assign(url);
        return;
      }

      $("#search_query").attr("data-search", query)
      var jobId = $("#job_id").val()
      var cabinests = $(".toggle-cabinet")

      for (var i = 0; i < cabinests.length; i++) {
        var cabinet = cabinests[i]
        var linesLoaderDiv = $(`.lines-loader-${jobId}-${cabinet.dataset.cabinetId}`)
        linesLoaderDiv.addClass('linesSpinnerDiv')
        
        var url = `/jobs/${jobId}?type=collections_search&cabinet_id=${cabinet.dataset.cabinetId}`
        if (query) {
          url = `${url}&search=${query}`
        }

        $.ajax({
          type: "GET",
          url: url,
          dataType: 'script'
        })
      }
    },
    render: {
      option: function(item, escape) {
        return `<div class ="selectize-custom-options">` + escape(item.cable) + `</div>`
      }
    }
  });
  $('.selectize-control').addClass('is-hidden')
  $($('.selectize-control')[0]).removeClass('is-hidden')
}


var getSearchedCable = function(filter) {
  // Select All tables and hide them.
  document.querySelectorAll('.table').forEach(table => {
    table.classList.add('is-hidden');
    owner = document.getElementById(table.dataset.owner);
    owner.childNodes[0].classList = 'fas fa-lg fa-angle-down';
  })

  // Select all table rows which doesn't contain header.
  trs = document.querySelectorAll('.table tbody tr');

  var count = trs.length;
  // Loop through each row.
  trs.forEach(tr => {
    tds = tr.cells  // Select all cells inside a row.
    td0 = tds[1].innerText.toLowerCase().indexOf(filter) > -1
    td1 = tds[2].innerText.toLowerCase().indexOf(filter) > -1
    td2 = tds[3].innerText.toLowerCase().indexOf(filter) > -1
    td3 = tds[4].innerText.toLowerCase().indexOf(filter) > -1
    td4 = tds[5].innerText.toLowerCase().indexOf(filter) > -1

    // Check if Assign Tech column, Cable, Area or device contains given search text.
    // IF yes, display that row and table, otherwise hide them.
    if (td0 || td1 || td2 || td3 || td4) {
      tr.classList.remove('is-hidden');

      table_ele = tr.closest('.table')

      if (table_ele.classList.contains('is-hidden')) {
        table_ele.classList.remove('is-hidden');
        owner = document.getElementById(table_ele.dataset.owner);
        owner.childNodes[0].classList = 'fas fa-lg fa-angle-up';
      }

    }else
      tr.classList.add('is-hidden');
  });

  count = $('tbody tr').length - $('tbody tr.is-hidden').length
  $('#search').parent().next().children().text('Total '+ count);
}

$(document).on('input', '#testing-search', function(event) {
  // Typed Value in Search Field.
  var filter_txt = $(this).val();

  clearTimeout(timer);  // Clear Time if time Function is called in given time interval.
  if ((filter_txt != '' && filter_txt) && value != filter_txt){
    // Check User typing completes and stop typing for 1 second.
    timer = setTimeout(function() {
      value = filter_txt;
      getSearchedLine(filter_txt.toLowerCase());
    }, 800);
  }else{
    count = $('tbody tr').length;
    $('.table').removeClass('is-hidden');
    $('.table tbody tr').removeClass('is-hidden');
    document.querySelectorAll('.table').forEach(table => {
      owner = document.getElementById(table.dataset.owner);
      owner.childNodes[0].classList = 'fas fa-lg fa-angle-down';
    });
  }
});

var getSearchedLine = function(filter) {
  // Select All tables and hide them.
  document.querySelectorAll('.table').forEach(table => {
    table.classList.add('is-hidden');
    owner = document.getElementById(table.dataset.owner);
    owner.childNodes[0].classList = 'fas fa-lg fa-angle-down';
  });

  // Select all table rows which doesn't contain header.
  trs = document.querySelectorAll('.table tbody tr');

  var count = trs.length;
  // Loop through each row.
  trs.forEach(tr => {
    tds = tr.cells  // Select all cells inside a row.
    td0 = tds[0].innerText.toLowerCase().indexOf(filter) > -1
    td1 = tds[1].innerText.toLowerCase().indexOf(filter) > -1
    ele = tds[2].getElementsByTagName('select')[0]
    td2 = ele.options[ele.selectedIndex].text.toLowerCase().indexOf(filter) > -1

    // Check if Assign Tech column, Cable, Area or device contains given search text.
    // IF yes, display that row and table, otherwise hide them.
    if (td0 || td1 || td2) {
      tr.classList.remove('is-hidden');

      table_ele = tr.closest('.table')

      if (table_ele.classList.contains('is-hidden')) {
        table_ele.classList.remove('is-hidden');
        owner = document.getElementById(table_ele.dataset.owner);
        owner.childNodes[0].classList = 'fas fa-lg fa-angle-up';
      }
    }else
      tr.classList.add('is-hidden');
  });
}