$(document).on('change', '.user_line_select', function(event){
  var jobId = event.target.dataset.jobId
  var userID = event.target.value
  $.ajax({
    type: "PUT",
    url: "/jobs/"+jobId+"/auto_assign_user",
    data: {user_id: userID},
    dataType: 'script'
  });
})


$(document).on('change', '#fluke_xml', function(event) {
  formData = new FormData();
  files = $(this)[0].files[0];
  var url = $('form#fluke-test-form').attr('action');
  $('.fluke-progress').parent().removeClass('is-hidden')
  formData.append('fluke_xml', files)

  jQuery.ajax({
    url: url,
    type: 'POST',
    data: formData,
    processData: false,
    contentType: false,
    beforeSend: function() {
      $('form div.loader').show();
    },
    complete: function(xhr, textStatus) {
      //called when complete
    },
    success: function(data, textStatus, xhr) {
      //called when successful
      $('form div.loader').hide();
      $('#flash').removeClass();
      button = $('<button>', {class: 'delete'});
      if (data.alert)
        $('#flash').addClass('notification is-danger').append(button).text(data.msg);
      else
        $('#flash').addClass('notification is-success').append(button).text(data.msg);
    },
    error: function(xhr, textStatus, errorThrown) {
      //called when there is an error
    }
  });
});

$(document).on('ajax:beforeSend', '#line-error', function() {
  $(this).parent('div').addClass('is-loading');
}).on('ajax:success', function() {
  $('#line-error').parent('div').removeClass('is-loading');
});
