document.addEventListener('turbolinks:load', () => {
  const toggle_modal_button = document.getElementById('toggle_modal');

  if (toggle_modal_button) {
    toggle_modal_button.addEventListener('click', (event) => {
      var target = toggle_modal_button.dataset.target;
      openModal(target);
    });
  }

  var $modals = document.querySelectorAll('.modal');
  // var $modalCloses = document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete');
  var $modalCloses = document.querySelectorAll('.modalClose');
  if ($modalCloses.length > 0) {
    $modalCloses.forEach( ($el) => {
      $el.addEventListener('click', () => {
        closeModals();
      });
    });
  }

  function openModal(target) {
    var $target = document.getElementById(target);
    if ($target) {
      $("body").addClass('body-overflow-hidden')
      $target.classList.add('is-active');
    }
  }

  document.addEventListener('keydown', function (event) {
    var e = event || window.event;
    if (e.keyCode === 27) {
      closeModals();
    }
  });

  function closeModals() {
    $modals.forEach(function ($el) {
      $("body").removeClass('body-overflow-hidden')
      $el.classList.remove('is-active');
    });
  }
});