$(document).on('click','a.toggle_line_item',function () {
  toggleCableState(this);
  let row = this.closest('tr');
  updateRowState(row);    
});

function toggleYesNo(element) {
  if (element.innerHTML.toUpperCase() === 'NO') {
    element.classList.remove('has-text-danger');
    element.classList.add('has-text-success');
    element.innerHTML = 'YES';
  } else {
    element.classList.remove('has-text-success');
    element.classList.add('has-text-danger');
    element.innerHTML = 'NO';
  }
}

function toggleCableState(element) {
  if (element.innerHTML.toUpperCase() === 'NO') {
    element.classList.remove('has-text-danger');
    element.classList.add('has-text-warning');
    element.innerHTML = '50%';
  } else if (element.innerHTML.toUpperCase() === 'YES') {
    element.classList.remove('has-text-success');
    element.classList.add('has-text-danger');
    element.innerHTML = 'NO';
  } else {
    element.classList.remove('has-text-warning');
    element.classList.add('has-text-success');
    element.innerHTML = 'YES';
  }
}

function updateRowState(row) {
  let states = {};
  let children = row.children;

  // Iterate over each datacell that whose first Child is an 'A' tag.
  // Then if that tag has a dataset, and that dataset has a field (which can
  // be any of pulled, dropped, dressed, or terminated), add that field and
  // the state of the field (the innerHTML) to the temp var 'states'
  for (let i in children) {
    let item = children[i];
    if (item.firstChild && item.firstChild.tagName == 'A') {
      if (item.firstChild.dataset && item.firstChild.dataset.field) {
        states[item.firstChild.dataset.field] = item.firstChild.innerHTML;
      }
    }
  }

  // Now iterate over the 'states' var. If any field is set to 'NO', then
  // mark the line as not completed.
  let completed = true;
  for (let key in states) {
    if (states[key].toUpperCase() === 'NO') {
      completed = false;
    }
  }

  // Decide the background color depending on the line completion state.
  if (completed) {
    row.classList.remove('has-background-danger-light');
    row.classList.add('has-background-success-light');
  } else {
    row.classList.remove('has-background-success-light');
    row.classList.add('has-background-danger-light');
  }
}

$(document).on('change', '#search_log', function() {
  $('.search_options').addClass('is-hidden')
  $('.filter_options').addClass('is-hidden')
  $('.log-filter-button').addClass('is-hidden')
  if (this.value != ''){
    $('.filter_options').removeClass('is-hidden')
    $('.log-filter-button').removeClass('is-hidden')
    $('#'+this.value).removeClass('is-hidden')
  }
});

document.addEventListener('turbolinks:load', () => {
  if( $('.jobs-line-list').length != 0 ){
    var line_list = $('.jobs-line-list')
    for(let tr of line_list){
      if (tr.children[1].rows.length == 0){
        tr.classList.add('is-hidden')
      }
    }
  }
});

$(document).on('click', '.add-unexpected-line', function() {
  $('#add-line-modal').addClass('is-active')
});

$(document).on('click', '.delete-add-line', function() {
  $(this).parents()[2].classList.remove('is-active')
});

$(document).on('change', '.edit-line-cable-state', function() {
  var stateValueId = this.dataset.stateValueId;
  var jobId = this.dataset.jobId;
  var lineId = this.dataset.lineId;

  $.ajax({
    type: 'PATCH',
    url: `/jobs/${jobId}/lines/${lineId}/state_values/${stateValueId}/toggle`,
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    },
    processData: false,
    contentType: false,
    success: function(response) {
      // Handle success, if needed
      console.log('Toggle success:');
    },
    error: function(xhr, status, error) {
      // Handle error, if needed
      console.error('Toggle error:');
    }
  });
});
