$( document ).ready(function() {
  $(document).on('click', '.idf-modal-button', function(event){
    var target = event.target.dataset.target;
    var $target = document.getElementById(target);
    if ($target) {
      $("body").addClass('body-overflow-hidden');
      $target.classList.add('is-active');
    }
  });

  $(document).on('click', '.idf-label-print-cable-box', function(event){
    var idfID = event.target.dataset.cabinetId;
    var jobId = document.getElementById('job_id').value;
    var ids = $('#line_ids').val().split(' ')
    
    var copies = $('#copies').val();
    var printer_name = $('#select-printer-'+idfID).val();
    var label_type = $('#select-label-type-'+idfID).val();
    printSelectedLines(ids, copies, printer_name, label_type, jobId)
  });

  $(document).on('click', '.idf-label-print', function(event){
    var idfID = event.target.dataset.cabinetId;
    var jobId = document.getElementById('job_id').value;
    var $checkboxes = document.querySelectorAll('.idf-checkbox');
    var ids = []

    if ($checkboxes.length > 0) {
      $checkboxes.forEach( ($el) => {
        if ($el.checked && $el.dataset.cabinetId === idfID) {
          ids.push($el.value);
        }
      });
    }
    
    var copies = $('#copies').val();
    var printer_name = $('#select-printer-'+idfID).val();
    var label_type = $('#select-label-type-'+idfID).val();
    printSelectedLines(ids, copies, printer_name, label_type, jobId)
  });
});

function printSelectedLines(ids, copies, printer_name, label_type, jobId) {
  $.ajax({
    url: '/lines/'+jobId+'/print',
    data: {line_ids: ids, copies: copies, printer_name: printer_name, label_type: label_type},
    success: function(data) {
      if (data.success){
        alert('Printing Notification Send Successfully');
      }else {
        alert('Please add lines');
      }
    }
  })
  .done(function() {
    
  })
  .fail(function() {
    
  })
  .always(function() {
    
  });
}

$(document).on('click', '.save-tech', function(event) {
  event.preventDefault();
  /* Act on the event */
  var idfID = event.target.dataset.cabinetId;
  var jobId = document.getElementById('job_id').value;
  var $checkboxes = document.querySelectorAll('.idf-checkbox');
  var ids = []
  if ($checkboxes.length > 0) {
    $checkboxes.forEach( ($el) => {
      if ($el.checked && $el.dataset.cabinetId === idfID) {
        ids.push($el.value);
      }
    });
  }

  if (ids.length == 0) {
    alert('Please select the lines first');
    return false
  }

  var tech_id = $('#select-tech-'+idfID).val();
  var state_id = $('#select-cable-state-'+idfID).val();
  
  $.ajax({
    url: '/lines/'+jobId+'/save_tech',
    type: 'POST',
    data: {line_ids: ids, tech_id: tech_id, state_id: state_id},
    beforeSend: function(){
    },
    success: function(data) {
      if (data != undefined && data.success) {
        alert(data.msg);
      }
    }
  })
  .done(function() {
    
  })
  .fail(function() {
    
  })
  .always(function() {
    
  });
});

$(document).on('click', '.delete, .idf-modal-close', function(event) {
  event.preventDefault();
  /* Act on the event */
  var idfID = event.target.dataset.target;
  var modal = document.getElementById(idfID);
  if (modal) {
    modal.classList.remove('is-active');
  }
});
