document.addEventListener('DOMContentLoaded', () => {

  $(document).on('change', '#cable_distribution_cable_state_id', function() {
    Rails.fire(this.form, 'submit');
  });

  $(document).on('click', '.add-custom-field', function() {
    event.preventDefault()
    var data = $('.table thead tr')
    var custom_input = $('.table thead tr th').last().clone()
    custom_input.children()[0].setAttribute('id', 'custom_fields'+ '_' +$('.custom_field').length)
    custom_input.children()[0].value = ''

    data.append(custom_input)

    var tbody_data = $('.cable-distribution tbody tr')

    for(let tr of tbody_data){
      var custom_input = $(tr).children().last().clone()
      custom_input.children()[0].setAttribute('id', 'custom_fields'+ '_' +$('.custom_field').length)
      tr.append(custom_input[0])
    }
  });

  $(document).on('click', '.bulk-update-button', function() {
    event.preventDefault()

    var area = $('#check_area')[0]
    var device = $('#check_device')[0]
    var switch_port = $('#check_switch_port')[0]
    var notes = $('#check_notes')[0]
    var cable_states = $('#check_cable_states')[0]
    var custom_fields = $('.check_custom_fields')
    if (area.checked){
      processCheckbox('.cable_distribution_area', true)
    }else {
      processCheckbox('.cable_distribution_area', false)
    }

    if (device.checked){
      processCheckbox('.cable_distribution_device', true)
    }else {
      processCheckbox('.cable_distribution_device', false)
    }

    if (switch_port.checked){
      processCheckbox('.cable_distribution_switch_port', true)
    }else {
      processCheckbox('.cable_distribution_switch_port', false)
    }

    if (notes.checked){
      processCheckbox('.cable_distribution_notes', true)
    }else {
      processCheckbox('.cable_distribution_notes', false)
    }

    if (cable_states.checked){
      processCheckbox('.cable_distribution_cable_states', true)
    }else {
      processCheckbox('.cable_distribution_cable_states', false)
    }

    for(let i of custom_fields){
      var a = i.id.replace('check', 'cable_distribution')
      if (i.checked){
        processCheckbox('.' + a, true)
      }else {
        processCheckbox('.' + a, false)
      }
    }

    $('#bulk-update').removeClass('is-active')
  });

  function processCheckbox(class_name, value) {
    var data = $(class_name)
    for(let i of data){
      i.checked = value
    }
  }

  $(document).on('click', '.save-cable-distribution', function() {
    var jobId = $('#job_id').val();
    var header = []
    const table = document.getElementById('rearrange-column-cable-distribution');
    var table_headers = table.querySelectorAll('th')
    table_headers.forEach( (el, i) => {

      var header_text
      if (el.textContent == ''){
        if (el.children[0] != undefined){
          header_text = el.children[0].value
        }
      }else{
        header_text = el.textContent
      }
      if (header_text != undefined){
        header.push(header_text)
      }
    });

    header = _.difference(header, ['Select', 'Location', 'Location #']);
    var reorder_column_msg = false
    updateReorderColumn(jobId, header, reorder_column_msg)

    var $checkboxes = document.querySelectorAll('.selected_cable_distribution');
    var ids = []
    var table_row = []
    if ($checkboxes.length > 0) {
      $checkboxes.forEach( ($el) => {
        if ($el.checked) {
          ids.push($el.value);
        }
      });
    }

    if (ids.length > 0) {
      _.find(ids, function(id) {
        table_row.push($('.cable-distribution-'+id))
      });
    } else {
      table_row = $('.cable-distribution')
    }

    var cable_distribution_data = []
    var table_header_data = [];

    table_header_data = getTableHeaders(table_header_data)
    var all_fields =['', 'Select', 'Location', 'Location #', 'Area', 'Device', 'Switch-Port', 'Notes', 'Cable states']
    // var custom_fields_header = table_header_data.filter(x => !all_fields.includes(x))
    var custom_fields_header = table_header_data
    var jobId = $('#job_id').val()
    for(var tr of table_row){
      row_data = tr[0] == undefined ? tr : tr[0]
      var hash = {}
      custom_fields_of_clones = $(".clone-table > tr > td > .cable_distribution_custom_fields")
      var custom_field_data = row_data.querySelectorAll('.cable_distribution_custom_fields')
      if (custom_field_data.length != 0){
        for (var i = 0; i < custom_fields_header.length; i++) {
          if (custom_fields_header[i] != undefined) {
            if (custom_fields_of_clones[i] == undefined) {
              hash[custom_fields_header[i]] = custom_field_data[i].checked;
            } else {
              hash[custom_fields_header[i]] = custom_fields_of_clones[i].checked;
            }
          }
        }
      }

      data = {
        id: row_data.querySelector('.selected_cable_distribution').value,
        area: row_data.querySelector('.cable_distribution_area').checked,
        device: row_data.querySelector('.cable_distribution_device').checked,
        switch_port: row_data.querySelector('.cable_distribution_switch_port').checked,
        notes: row_data.querySelector('.cable_distribution_notes').checked,
        cable_states: row_data.querySelector('.cable_distribution_cable_states').checked,
        job_id: jobId
      }

      if (!_.isEmpty(hash)) {
        Object.assign(data, {custom_fields: hash})
      }

      cable_distribution_data.push(data)
    }

    var fd = new FormData();
    fd.append('data', JSON.stringify(cable_distribution_data));

    $.ajax({
      type: 'PATCH',
      url: `/jobs/${jobId}/cable_distribution/bulk_update`,
      data: fd,
      processData: false, // required
      contentType: false,
      dataType: 'script',
    }).done(function(data) {
      // document.getElementsByClassName('pageloader')[0].classList.add('is-active');
      alert("cable distribution update successfully")
    });
  });

  function updateReorderColumn(jobId, header, reorder_column_msg){
    $.ajax({
      type: 'GET',
      url: '/jobs/'+ jobId +'/reorder_line_column',
      data: {
        line_header: header
      },
      dataType: 'json',
    }).done(function() {
      if (reorder_column_msg == true) {
        $('.reorder-line-button').addClass('is-hidden')
        $('.action-button').removeClass('is-hidden')
        // document.getElementsByClassName('pageloader')[0].classList.add('is-active');
        alert("Reorder columns successfully")
      }
    });
  }

  function getTableHeaders(table_header_data) {
    var cloned_custom_fields = $(".clone-table > tr > th.custom-th")
    var default_custom_fields = $('table.cable-distribution thead tr th.custom-th')
    for (var i = 0; i < default_custom_fields.length; i++) {
      if (cloned_custom_fields[i] == undefined) {
        var data = $(default_custom_fields[i].children).val()
      } else {
        var data = $(cloned_custom_fields[i].children).val()
      }
      if (data != "") { 
        table_header_data.push(data)
      }
    }
    return table_header_data
  };
});

document.addEventListener('turbolinks:load', () => {
  const table = document.getElementById('rearrange-column-cable-distribution');

  let draggingEle;
  let draggingColumnIndex;
  let placeholder;
  let list;
  let isDraggingStarted = false;

  // The current position of mouse relative to the dragging element
  let x = 0;
  let y = 0;

  // Swap two nodes
  const swap = function (nodeA, nodeB) {
      const parentA = nodeA.parentNode;
      const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

      // Move `nodeA` to before the `nodeB`
      nodeB.parentNode.insertBefore(nodeA, nodeB);

      // Move `nodeB` to before the sibling of `nodeA`
      parentA.insertBefore(nodeB, siblingA);
  };

  // Check if `nodeA` is on the left of `nodeB`
  const isOnLeft = function (nodeA, nodeB) {
      // Get the bounding rectangle of nodes
      const rectA = nodeA.getBoundingClientRect();
      const rectB = nodeB.getBoundingClientRect();

      return rectA.left + rectA.width / 2 < rectB.left + rectB.width / 2;
  };

  const cloneTable = function () {
      const rect = table.getBoundingClientRect();

      list = document.createElement('div');
      list.classList.add('clone-list');
      list.style.position = 'absolute';
      table.parentNode.insertBefore(list, table);

      // Hide the original table
      table.style.visibility = 'hidden';

      // Get all cells
      const originalCells = [].slice.call(table.querySelectorAll('tbody td'));

      const originalHeaderCells = [].slice.call(table.querySelectorAll('th'));
      const numColumns = originalHeaderCells.length;

      // Loop through the header cells
      originalHeaderCells.forEach(function (headerCell, headerIndex) {
          const width = parseInt(window.getComputedStyle(headerCell).width);

          // Create a new table from given row
          const item = document.createElement('div');
          item.classList.add('draggable');

          const newTable = document.createElement('table');
          newTable.setAttribute('class', 'clone-table');
          newTable.style.width = `${width}px`;

          // Header
          const th = headerCell.cloneNode(true);
          let newRow = document.createElement('tr');
          newRow.appendChild(th);
          newTable.appendChild(newRow);

          const cells = originalCells.filter(function (c, idx) {
              return (idx - headerIndex) % numColumns === 0;
          });

          cells.forEach(function (cell) {
              const newCell = cell.cloneNode(true);
              newCell.style.width = `${width}px`;
              newRow = document.createElement('tr');
              newRow.appendChild(newCell);
              newTable.appendChild(newRow);
          });

          item.appendChild(newTable);
          list.appendChild(item);
      });
  };

  const mouseDownHandler = function (e) {
      if(e.target.classList.contains('non-draggable')) {
        return;
      }

      draggingColumnIndex = [].slice.call(table.querySelectorAll('th')).indexOf(e.target);

      // Determine the mouse position
      x = e.clientX - e.target.offsetLeft;
      y = e.clientY - e.target.offsetTop;

      // Attach the listeners to `document`
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
  };

  const mouseMoveHandler = function (e) {
      if (!isDraggingStarted) {
          isDraggingStarted = true;

          cloneTable();

          draggingEle = [].slice.call(list.children)[draggingColumnIndex];
          draggingEle.classList.add('dragging');

          // Let the placeholder take the height of dragging element
          // So the next element won't move to the left or right
          // to fill the dragging element space
          placeholder = document.createElement('div');
          placeholder.classList.add('placeholder');
          draggingEle.parentNode.insertBefore(placeholder, draggingEle.nextSibling);
          placeholder.style.width = `${draggingEle.offsetWidth}px`;
      }

      // Set position for dragging element
      draggingEle.style.position = 'absolute';
      draggingEle.style.top = `${draggingEle.offsetTop + e.clientY - y}px`;
      draggingEle.style.left = `${draggingEle.offsetLeft + e.clientX - x}px`;

      // Reassign the position of mouse
      x = e.clientX;
      y = e.clientY;

      // The current order
      // prevEle
      // draggingEle
      // placeholder
      // nextEle
      const prevEle = draggingEle.previousElementSibling;
      const nextEle = placeholder.nextElementSibling;

      // // The dragging element is above the previous element
      // // User moves the dragging element to the left
      if (prevEle && isOnLeft(draggingEle, prevEle)) {
          // The current order    -> The new order
          // prevEle              -> placeholder
          // draggingEle          -> draggingEle
          // placeholder          -> prevEle
          swap(placeholder, draggingEle);
          swap(placeholder, prevEle);
          return;
      }

      // The dragging element is below the next element
      // User moves the dragging element to the bottom
      if (nextEle && isOnLeft(nextEle, draggingEle)) {
          // The current order    -> The new order
          // draggingEle          -> nextEle
          // placeholder          -> placeholder
          // nextEle              -> draggingEle
          swap(nextEle, placeholder);
          swap(nextEle, draggingEle);
      }
  };

  const mouseUpHandler = function () {
      // // Remove the placeholder
      placeholder && placeholder.parentNode.removeChild(placeholder);

      draggingEle.classList.remove('dragging');
      draggingEle.style.removeProperty('top');
      draggingEle.style.removeProperty('left');
      draggingEle.style.removeProperty('position');

      // Get the end index
      const endColumnIndex = [].slice.call(list.children).indexOf(draggingEle);

      isDraggingStarted = false;

      // Remove the `list` element
      list.parentNode.removeChild(list);

      // Move the dragged column to `endColumnIndex`
      table.querySelectorAll('tr').forEach(function (row) {
          const cells = [].slice.call(row.querySelectorAll('th, td'));
          draggingColumnIndex > endColumnIndex
              ? cells[endColumnIndex].parentNode.insertBefore(
                    cells[draggingColumnIndex],
                    cells[endColumnIndex]
                )
              : cells[endColumnIndex].parentNode.insertBefore(
                    cells[draggingColumnIndex],
                    cells[endColumnIndex].nextSibling
                );
      });

      // Bring back the table
      table.style.removeProperty('visibility');

      // Remove the handlers of `mousemove` and `mouseup`
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);

    $('.reorder-line-button').removeClass('is-hidden')
    $('.action-button').addClass('is-hidden')
  };

  if (table != null){
    table.querySelectorAll('th').forEach(function (headerCell, headerIndex) {
      if (headerIndex != 0 && headerIndex != 1 && headerIndex != 2 && headerIndex != 3 && headerIndex != table.querySelectorAll('th').length - 1 ){
        headerCell.classList.add('draggable');
        headerCell.addEventListener('mousedown', mouseDownHandler);
      }
    });
  }
});

$(document).on('change', '.cable_distribution_location', function() {
  update_location_name_no()
});

$(document).on('change', '.cable_distribution_location_no', function() {
  update_location_name_no()
});

function update_location_name_no(){
  var ids = []
  var table_row = []

  if (ids.length > 0) {
    _.find(ids, function(id) {
      table_row.push($('.cable-distribution-'+id))
    });
  } else {
    table_row = $('.cable-distribution')
  }

  var cable_distribution_data = []
  var jobId = $('#job_id').val()
  for(var tr of table_row){
    row_data = tr[0] == undefined ? tr : tr[0]

    data = {
      id: row_data.querySelector('.selected_cable_distribution').value,
      location: row_data.querySelector('.cable_distribution_location').value,
      location_no: row_data.querySelector('.cable_distribution_location_no').value,
      job_id: jobId
    }

    cable_distribution_data.push(data)
  }

  var fd = new FormData();
  fd.append('data', JSON.stringify(cable_distribution_data));

  $.ajax({
    type: 'PATCH',
    url: `/jobs/${jobId}/cable_distribution/bulk_update`,
    data: fd,
    processData: false, // required
    contentType: false,
    dataType: 'script',
  });
}

document.addEventListener('turbolinks:load', () => {
  var jobId = $('#job_id').val()
  $("#reorder_rows").sortable({
    animation: 150,
    ghostClass: 'blue-background-class',
    update: function(e, ui) {
      $.ajax({
        url: `/jobs/${jobId}/cable_distribution/re_order_rows`,
        type: "PATCH",
        data: $(this).sortable('serialize'),
      });
    }
  });
});