document.addEventListener('turbolinks:load', () => {
  const fileInput = document.querySelector('#upload-document input[type=file]');
  if (fileInput) {
    fileInput.onchange = () => {
      if (fileInput.files.length > 0) {
        const fileName = document.querySelector('#upload-document .file-name');
        fileName.textContent = fileInput.files[0].name;
      }
    }
  }

  $(document).on('change', 'input[type="file"]', function(e) {
    // var fileLabel = document.querySelector('#upload-project-document .project-document');
    var fileLabel = e.target.parentElement
    fileLabel.lastElementChild.innerText = this.files[0].name;
  });
});