import consumer from "./consumer"

consumer.subscriptions.create({channel: "TestingChannel"}, {
  connected() {
    let job_id = $('#testingJob').attr('data-job-id')
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    var element = $("#testing-div-"+data.job_id+'-'+data.line_id);
    if (element[0] != undefined)
      element.replaceWith(data.item);
    else
      $('table.testingTable#table_cabinet_'+data.cabinet_id+' tbody').append(data.item);

    if (data.failed_lines != undefined){
      $('#view_error_lines')[0].children[0].innerHTML = 'View Error ' + data.failed_lines + '/' + data.expected_lines
    }
  },
});
