var autocomplete;
var address1Field;
var address2Field;
var postalField;

function initAutocomplete() {
  address1Field = $("#address_1")[0];
  if (address1Field == null || address1Field == undefined) {
    return true
  }

  address2Field = $("#address_2")[0];
  postalField = $("#zipcode")[0];
  
  autocomplete = new google.maps.places.Autocomplete(address1Field, {
      componentRestrictions: { country: ["us"] },
      fields: ["address_components", "geometry"],
      types: ["address"],
  });
  address1Field.focus();
  
  autocomplete.addListener("place_changed", fillInAddress);
}

function fillInAddress() {
  var place = autocomplete.getPlace();
  var address1 = "";
  var postcode = "";

  setLatLongValue(place.geometry.location);
  
  for (var _i = 0, _a = place.address_components; _i < _a.length; _i++) {
    var component = _a[_i];
    var componentType = component.types[0];
    switch (componentType) {
      case "street_number": {
        address1 = "".concat(component.long_name, " ").concat(address1);
        break;
      }
      case "route": {
        address1 += component.short_name;
        break;
      }
      case "postal_code": {
        postcode = "".concat(component.long_name).concat(postcode);
        break;
      }
      case "postal_code_suffix": {
        postcode = "".concat(postcode, "-").concat(component.long_name);
        break;
      }
      case "sublocality_level_1":
        $("#city")[0].value = component.long_name;
        break;
      case "locality":
        $("#city")[0].value = component.long_name;
        break;
      case "administrative_area_level_1": {
        $("#state")[0].value = component.short_name;
        break;
      }
      case "country":
        $("#country")[0].value = component.long_name;
        break;
    }
  }
  address1Field.value = address1;
  postalField.value = postcode;
  
  address2Field.focus();

  // Get timezone based on coordinates
  // getTimezone(place.geometry.location.lat(), place.geometry.location.lng())
  //   .done(function(data) {
  //     console.log("Timezone:", data.timeZoneId);
  //     // Set timezone value to a hidden field or perform further actions as needed
  //     $("#time_zone")[0].value = data.timeZoneId;
  //   })
  //   .fail(function(jqXHR, textStatus, errorThrown) {
  //     console.error("Error fetching timezone:", textStatus, errorThrown);
  //     // Handle errors if necessary
  //   });
}

function getTimezone(lat, lng) {
  var timestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
  var apiUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=AIzaSyDwi2OYfyFcXZmBA47c36eQ0a7ByyF7RV4`;

  // Replace 'YOUR_GOOGLE_MAPS_API_KEY' with your actual Google Maps API key

  return $.ajax({
    url: apiUrl,
    method: 'GET',
    dataType: 'json'
  });
}

function setLatLongValue(location) {
  if($("#job_lat")[0] != undefined || $("#job_long")[0] != undefined) {
    $("#job_lat")[0].value = location.lat();
    $("#job_long")[0].value = location.lng();
  }
}

window.initAutocomplete = initAutocomplete;
