document.addEventListener('turbolinks:load', () => {
  var zoom = 400
  let imgContain = document.getElementById('svg-container')
  if (imgContain) {
    $(document).on('click','#zoomIn',function () {
      $(imgContain).width($(imgContain).width()+zoom);
      $(imgContain).height($(imgContain).height()+zoom);
    });

    $(document).on('keydown', function(e) {
      let planModal = document.getElementById('plan_modal')
      if (planModal) {
        var key = e.key;
        if (e.ctrlKey) key = 'Ctrl+' + key;
        switch (key) {
          case 'Ctrl+z':
          case 'Ctrl+Z':
            $(imgContain).width($(imgContain).width()+zoom);
            $(imgContain).height($(imgContain).height()+zoom);
          break;
          case 'Ctrl+X':
          case 'Ctrl+x':
            if ($(imgContain).width() > 600 && $(imgContain).height() > 600) {
              $(imgContain).width($(imgContain).width()-zoom);
              $(imgContain).height($(imgContain).height()-zoom);
            }
          break;
          default: return;
        }
      }
    })

    let planImgCont = document.getElementById('planImageCont')

    $(planImgCont).on('mousewheel', function(event) {

      var wDelta = event.wheelDelta
      var svg_container = this.children[0]
      if (svg_container.style.transform != "") {
        var value = svg_container.style.transform
        var scal_value = parseFloat(value.match(/\((.*)\)/)[1])
      } else {
        var scal_value = 1.2
      }
      let planModal = document.getElementById('plan_modal')
      if (scal_value >= 1.2){
        if (event.originalEvent.deltaY < 0){
          scal_value += 0.2
          svg_container.style.transform = "scale("+ scal_value +")"
        }else{
          scal_value -= 0.2
          scal_value = scal_value < 1.2 ? 1.2 : scal_value
          svg_container.style.transform = "scale("+ scal_value +")"
        }
        svg_container.style.transition = '1s'
      }
    });

    $(document).on('click','#zoomOut',function () {
      if ($(imgContain).width() > 600 && $(imgContain).height() > 600) {
        $(imgContain).width($(imgContain).width()-zoom);
        $(imgContain).height($(imgContain).height()-zoom);
      }
    });
    $(document).on('click','#reset',function () {
      $(imgContain).width('100%');
      $(imgContain).height('900px');
      imgContain.style.top = '0px'
      imgContain.style.left = '0px'
      imgContain.style.right = '0px'
    });

    $('.loader-div').addClass('spinnerDiv')
    $(imgContain).bind('load', function() {
      $('.loader-div').removeClass('spinnerDiv')
      
    });


    $(function() {
      $(imgContain).dragZoom({
        scope: $("body"),
        zoom: 1,
        onWheelStart: function(e) {
          
        },
        onWheelEnd: function(e) {
          
        },
        onDragStart: function(e) {
          
        },
        onDragMove: function(e) {
          
        },
        onDragEnd: function(e) {
          
        }
      }, function() {});
    });
  }
})

