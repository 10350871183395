localStorage.setItem('slideIndex', 1)

$(document).on('click', '.project-docs', function() {
  $('#document_modal').addClass('is-active')
});

$(document).on('click', '.job_schedule', function() {
  $.ajax({
    type: "GET",
    url: '/admin/jobs/new_schedule',
    dataType: 'script'
  })
})

$(document).on('click', '.plan-docs', function() {
  $('#plan_download_modal').addClass('is-active')
});

$(document).on('click', '.project-docs', function() {
  $('#document_modal_pop').addClass('is-active')
});


$(document).on('change', '#job_plans', function() {
  var input = document.getElementById('job_plans');
  var children = "";

  if ($('#table-plans > tbody:last > tr').text() == 'No plans') {
    $('#table-plans > tbody:last').html('');
  }

  for (var i = 0; i < input.files.length; ++i) {
    $('#table-plans > tbody:last').append('<tr><td>' + input.files[i].name + '</td><td>(not yet saved)</td></tr>');
  }
});

$(document).on('change', '.job-image', function() {
  var input = document.getElementById('image');
  var children = "";

  if ($('#table-job-images > tbody:last > tr').text() == 'No images') {
    $('#table-job-images > tbody:last').html('');
  }

  for (var i = 0; i < input.files.length; ++i) {
    $('#table-job-images > tbody:last').append('<tr><td colsapn="2" style="text-align: center; height:100px;width:100px" id='+ i +'></td><td><input type="text" placeholder="rename image(optional)" name="rename_image['+ input.files[i].name +']" id='+ input.files[i].name +'></td></tr>');

    var image = window.URL.createObjectURL(input.files[i]);
    $("#"+ i).css("background-image", "url(" + image + ")");
    $("#"+ i).css("background-size", "80px 80px")
    $("#"+ i).css("background-repeat", "no-repeat")
  }
});

$(document).on('change', '.new-job-image', function() {
  var input = document.getElementById('collection_image_images');
  var children = "";

  if ($('#table-new-job-images > tbody:last > tr').text() == 'No images') {
    $('#table-new-job-images > tbody:last').html('');
  }

  for (var i = 0; i < input.files.length; ++i) {
    $('#table-new-job-images > tbody:last').append('<tr><td colsapn="2" style="text-align: center; height:100px;width:100px" id='+ i +'></td><td><input type="text" placeholder="rename image(optional)" class="rename-image-field" name="rename_image['+ input.files[i].name +']" id='+ input.files[i].name +'></td></tr>');

    var image = window.URL.createObjectURL(input.files[i]);
    
    $("#"+ i).css("background-image", "url(" + image + ")");
    $("#"+ i).css("background-size", "80px 80px")
    $("#"+ i).css("background-repeat", "no-repeat")
    // indexdbJobDataCreate(image, input.files[i].name)
  }
});

const dbImageName = 'cableman';
const dbVersion = 2
const dbImageStoreName = 'jobImages'

function updateUi() {
  
}

$(document).on('click', '.save-new-collec-img', function() {
  $('#modal-new-image-job')[0].classList.remove('is-active')
  var input = document.getElementById('collection_image_images');
  const maxFiles = 20;
  if (input.files.length > maxFiles) {
    // Display validation message if more than allowed files are selected
    alert(`You can only upload ${maxFiles} files at a time.`);
    // Clear input field to prevent exceeding the limit
    return false;
  }

  var images = []
  var renameImage = {}
  var renameImageCollection = []
  var renameImageDataColl = {}
  var renameImageFormData = {}
  var formData = new FormData();

  var renameFields = $(".rename-image-field") 

  for (var k = 0; k < renameFields.length; ++k) {
    var blobUrl = renameFields[k].parentElement.parentElement.children[0].style.backgroundImage.split('"')[1]
    renameImage[renameFields[k].id] = renameFields[k].value
    renameImageDataColl[blobUrl] = renameFields[k].value
    renameImageFormData[renameFields[k].id] = {renameField: renameFields[k].value}
    formData.append(`rename_image[${renameFields[k].id}]`, renameFields[k].value);
  }

  

  for (var i = 0; i < input.files.length; ++i) {
    // var image = window.URL.createObjectURL(input.files[i]);
    // images.push(image)
    var fileObj = {}
    const file = input.files[i]
    var fileName = file.name
    var base64File = getBase64(file);

    fileObj["file"] = file;
    fileObj["fileName"] = fileName;

    for (var j = 0; j < renameFields.length; ++j) {
      if (renameFields[j].id === fileName) {
        fileObj["renameField"] = renameFields[j].value
      }
    }
    // fileObj["renameField"] = $(`${fileName}`).val()
    renameImageCollection.push(fileObj)
    formData.append('collection_image[images][]', file);

  }  

  // 
  var inputData = input.cloneNode(true)
  var folderName = $("#collection_image_folder_name").val()
  var jobId = $("#job_id").val()
  formData.append('collection_image[folder_name]', folderName);
  formData.append('job_id', jobId);
  formData.append('commit', 'save');

  processImageData(jobId, formData, inputData, renameImageCollection, renameImageFormData, renameImageDataColl)
});

async function getBase64(file) {
  var reader = new FileReader();
  await reader.readAsDataURL(file);
  reader.onload = function () {
   return reader.result;
  };
  reader.onerror = function (error) {
   return error
  };
}
async function processImageData(jobId, formData, inputData, renameImageCollection, renameImageFormData, renameImageDataColl) {
  const authenticityToken = $("#authenticity_token").val();
  const url = `/collection_images.js`;
  var folderName = $("#collection_image_folder_name").val();
  var params = new FormData();

  const totalFiles = formData.getAll('collection_image[images][]');

  console.log("Starting upload process"); // Debug log

  // Show the progress bar container
  document.getElementById('progress-bar-container').style.display = 'block';

  let totalUploadedSize = 0;
  const totalSize = totalFiles.reduce((acc, file) => acc + file.size, 0);
  console.log("Total size of files:", totalSize); // Debug log

  for (let i = 0; i < totalFiles.length; i++) {
    const file = totalFiles[i];
    console.log(`Processing file ${i + 1} of ${totalFiles.length}: ${file.name}`); // Debug log

    const rename_value = await renameImageCollection.find(a => a.file == file).renameField;
    const prevFile = totalFiles[i - 1];
    params.delete('collection_image[images][]');
    if (prevFile !== undefined && prevFile !== null) {
      params.delete(`rename_image[${prevFile.name}]`);
    }

    params.append('job_id', jobId);
    params.append('collection_image[folder_name]', folderName);
    params.append('collection_image[images][]', file);
    params.append('commit', 'save');
    params.append(`rename_image[${file.name}]`, rename_value);

    const xhr = new XMLHttpRequest();

    // Show loader on current image thumbnail
    const currentThumbnail = document.querySelectorAll('.thumbnail-container')[i];

    if (currentThumbnail) {
      currentThumbnail.querySelector('.loader').style.display = 'block';
    }

    xhr.upload.addEventListener('progress', function(event) {
      if (event.lengthComputable) {
        const percent = Math.round((totalUploadedSize + event.loaded) / totalSize * 100);
        console.log(`File ${i + 1} progress: ${percent}%`); // Debug log
        document.getElementById('progress-bar').value = percent;
        document.getElementById('progress-text').innerText = `${percent}%`;
      }
    });

    xhr.upload.addEventListener('load', function(event) {
      totalUploadedSize += file.size;
      console.log(`File ${i + 1} Upload Complete`);

      // Hide loader and remove thumbnail after processing
      if (currentThumbnail) {
        currentThumbnail.querySelector('.loader').style.display = 'none';
        currentThumbnail.querySelector('.status-text').innerText = 'Uploaded';
        setTimeout(() => {
          currentThumbnail.remove();
        }, 2000); // Adjust delay as needed
      }

      // Update progress bar and text
      const percentComplete = Math.round((totalUploadedSize / totalSize) * 100);
      console.log(`Total progress: ${percentComplete}%`); // Debug log
      document.getElementById('progress-bar').value = percentComplete;
      document.getElementById('progress-text').innerText = `${percentComplete}%`;
    });

    xhr.upload.addEventListener('error', function(event) {
      console.error(`File ${i + 1} Upload Error`);
      if (currentThumbnail) {
        currentThumbnail.querySelector('.status-text').innerText = 'Error';
      }
    });

    xhr.open('POST', url, true);
    xhr.setRequestHeader('X-CSRF-Token', authenticityToken);

    xhr.onload = function() {
      if (xhr.status === 200) {
        console.log(`Server response for file ${i + 1}:`, xhr.responseText); // Debug log

        if (i === totalFiles.length - 1) {
          console.log(`All files uploaded successfully, Count: ${i + 1}`);
          const plainFormData = Object.fromEntries(formData.entries());
          const formDataJsonString = JSON.stringify(plainFormData);
          updateModalUi(formDataJsonString, inputData, renameImageCollection, renameImageDataColl);
          clearDataFromIndexdbStore();

          // Hide the progress bar
          document.getElementById('progress-bar-container').style.display = 'none';
        }
      } else {
        console.error(`File ${i + 1} Upload Failed`);
        if (currentThumbnail) {
          currentThumbnail.querySelector('.status-text').innerText = 'Error';
        }
      }
    };

    console.log(`Sending request for file ${i + 1}`); // Debug log
    xhr.send(params);
  }
}


// async function processImageData(jobId, formData, inputData, renameImageCollection, renameImageFormData, renameImageDataColl) {
//   const authenticityToken = $("#authenticity_token").val();
//   const url = `/collection_images.js`;
//   var folderName = $("#collection_image_folder_name").val();
//   var params = new FormData();

//   const totalFiles = formData.getAll('collection_image[images][]');

//   // Show the progress bar container
//   document.getElementById('progress-bar-container').style.display = 'block';

//   let totalUploaded = 0;
//   const totalSize = totalFiles.reduce((acc, file) => acc + file.size, 0);

//   for (let i = 0; i < totalFiles.length; i++) {
//     const file = totalFiles[i];
//     const rename_value = await renameImageCollection.find(a => a.file == file).renameField;
//     const prevFile = totalFiles[i-1];
//     params.delete('collection_image[images][]');
//     if (prevFile !== undefined && prevFile !== null) {
//       params.delete(`rename_image[${prevFile.name}]`);
//     }

//     params.append('job_id', jobId);
//     params.append('collection_image[folder_name]', folderName);
//     params.append('collection_image[images][]', file);
//     params.append('commit', 'save');
//     params.append(`rename_image[${file.name}]`, rename_value);

//     const xhr = new XMLHttpRequest();

//     // Show loader on current image thumbnail
//     const currentThumbnail = document.querySelectorAll('.thumbnail-container')[i];

//     if (currentThumbnail) {
//       currentThumbnail.querySelector('.loader').style.display = 'block';
//     }

//     xhr.upload.addEventListener('progress', function(event) {
//       if (event.lengthComputable) {
//         // totalUploaded += event.loaded;
//         const percent = Math.round((totalUploaded + event.loaded / file.size) / totalFiles.length * 100);
//         // const percent = Math.round((totalUploaded + event.loaded) / totalSize * 100);
//         document.getElementById('progress-bar').value = percent;
//         document.getElementById('progress-text').innerText = `${percent}%`;
//       }
//     });

//     xhr.upload.addEventListener('load', function(event) {
//       // totalUploaded += file.size;
//       totalUploaded++;
//       console.log(`File ${i + 1} Upload Complete`);
//       // Hide loader and remove thumbnail after processing
//       if (currentThumbnail) {
//         currentThumbnail.querySelector('.loader').style.display = 'none';
//         currentThumbnail.querySelector('.status-text').innerText = 'Uploaded';
//         setTimeout(() => {
//           currentThumbnail.remove();
//         }, 2000); // Adjust delay as needed
//       }

//         // Update progress bar and text
//         const percentComplete = Math.round((totalUploaded / totalFiles.length) * 100);
//         document.getElementById('progress-bar').value = percentComplete;
//         document.getElementById('progress-text').innerText = `${totalUploaded}/${totalFiles.length} files uploaded`;
//     });

//     xhr.upload.addEventListener('error', function(event) {
//       console.error(`File ${i + 1} Upload Error`);
//       // Update status text on error
//       if (currentThumbnail) {
//         currentThumbnail.querySelector('.status-text').innerText = 'Error';
//       }
//     });

//     xhr.open('POST', url, true);
//     xhr.setRequestHeader('X-CSRF-Token', authenticityToken);

//     xhr.onload = function() {
//       if (xhr.status === 200) {
//         if (i === totalFiles.length - 1) {
//           console.log(`All files uploaded successfully, Count: ${i + 1}`);
//           const plainFormData = Object.fromEntries(formData.entries());
//           const formDataJsonString = JSON.stringify(plainFormData);
//           updateModalUi(formDataJsonString, inputData, renameImageCollection, renameImageDataColl);
//           clearDataFromIndexdbStore();

//           // Hide the progress bar
//           document.getElementById('progress-bar-container').style.display = 'none';
//         }
//       } else {
//         console.error(`File ${i + 1} Upload Failed`);
//         if (currentThumbnail) {
//           currentThumbnail.querySelector('.status-text').innerText = 'Error';
//         }
//       }
//     };

//     xhr.send(params);
//   }
// }

function updateListWithImages(formDataJsonString, renameImageCollection, renameImageDataColl) {
  var data = JSON.parse(formDataJsonString)
  var folder_name = data["collection_image[folder_name]"]
  var imageRecords = renameImageCollection

  // for (var i = 0; i < renameImageCollection.length; ++i) {
  for (const [key, value] of Object.entries(renameImageDataColl)) {
    // var fileObj = renameImageCollection[i]

    var imageContainer = createImageDiv(key, value)

    noImageText = ''
    
    if (folder_name === '') {
      if ($("#non-category").length > 0) {
        $("#non-category").append(imageContainer)
        return
      } else {
        var anyPrent = $(".collection-img")
        var parentDiv1 = document.createElement('div');
        parentDiv1.className = "columns flex-wrapper-images folder-name-div mb-5"
        parentDiv1.id = "non-category"
        anyPrent.append(parentDiv1)
        parentDiv1.append(imageContainer)
        return
      }
    }

    var containerTable = $(`.${folder_name}`)
    var childEle = containerTable.children()[0].children[1]
    if (childEle) {
      var noImageText = childEle.innerText
    }

    if (childEle && !childEle.hidden && noImageText === 'No images') {
      childEle.hidden = true
      var parentDiv = document.createElement('div');
      parentDiv.className = `columns flex-wrapper-images folder-name-div mb-5 main-image-${folder_name}`

      $(`.${folder_name}`).append(parentDiv)
      parentDiv.append(imageContainer)
    } else {
      var mainImageDiv = $(`.main-image-${folder_name}`)
      mainImageDiv.append(imageContainer)
    }
  }
}

function createImageDiv(imageUrl, imageName) {
  // var blobUrl = $(`#${fileName}`).parentElement.parentElement.children[0].style.backgroundImage.split('"')[1]

  var div1 = document.createElement('div');
  div1.className = "column is-3 mb-4 mobile-display-img";

  var anchor1 = document.createElement('a');
  anchor1.className = "show_photo";
  anchor1.href="javascript: void(0)"

  div1.appendChild(anchor1)

  var anchor1Child1 = document.createElement('div');
  anchor1Child1.className = "image-blob";

  anchor1.appendChild(anchor1Child1)

  var imageTag = document.createElement('IMG');
  imageTag.src = imageUrl;
  // x.setAttribute("src", "img_pulpit.jpg");
  imageTag.width = "40";
  imageTag.height="40";
  anchor1Child1.appendChild(imageTag)

  var anchor1Child2 = document.createElement('div');
  anchor1Child2.className = "image-name has-text-centered";
  anchor1Child2.innerHTML = imageName;
  
  anchor1.appendChild(anchor1Child2)

  return div1
}

function updateModalUi(formDataJsonString, inputData, renameImageCollection, renameImageDataColl) {
  

  var data = JSON.parse(formDataJsonString)
  var folder_name = data["collection_image[folder_name]"]

  closeModalAndRemoveForm()
  updateListWithImages(formDataJsonString, renameImageCollection, renameImageDataColl)
}

function closeModalAndRemoveForm() {
  $('#modal-new-image-job').removeClass('is-active')
  var new_collection_img = $('.new_collection_image')[0]
  new_collection_img.elements[1].value = ""
  new_collection_img.elements[2].value = ""
  new_collection_img.querySelector('.file-name').textContent =  "No images uploaded"
  var table_new_job_img = $('#table-new-job-images')[0]
  table_new_job_img.tBodies[0].innerHTML = "<tr><td colspan='5'>No images</td></tr>"
}

async function deleteImageDataFromIndexdb(dbStore, ID) {
  let request = await self.indexedDB.open(dbImageName, dbVersion);
  request.onsuccess = function(event) {
    var transaction = this.result.transaction(dbStore, 'readwrite');
    var collection = transaction.objectStore(dbStore);
    let reqs = collection.delete(ID);
    transaction.complete;
    reqs.onsuccess = function() {
      // storeDynamicJos(cabinet)
      
    };
    reqs.onerror = function() {
      
    };
  }
}

async function indexdbJobDataCreate(jobId, url, formDataJsonString, inputData, renameImageCollection, authenticityToken, renameImageFormData, renameImageDataColl) {
  var fd = JSON.parse(formDataJsonString)
  const data = {id: jobId, url: url, formData: fd, authenticityToken: authenticityToken, renameImageCollection: renameImageCollection}
  let request = await self.indexedDB.open(dbImageName, dbVersion);
  request.onsuccess = function(event) {
    
    
    var transaction = this.result.transaction(dbImageStoreName, 'readwrite');
    var collection = transaction.objectStore(dbImageStoreName);
    let reqs = collection.put(data);
    transaction.complete;
    reqs.onsuccess = function() {
      // storeDynamicJos(cabinet)
      
      initImagesBgroundSync()
      updateModalUi(formDataJsonString, inputData, renameImageCollection, renameImageDataColl)
    };

    reqs.onerror = function() {
      
    };
  }
}

async function clearDataFromIndexdbStore() {
  let request = await self.indexedDB.open(dbImageName, dbVersion);
  request.onsuccess = function(event) {
    var transaction = this.result.transaction(dbImageStoreName, 'readwrite');
    var collection = transaction.objectStore(dbImageStoreName);
    let reqs = collection.clear();
    transaction.complete;
    reqs.onsuccess = function() {
      // storeDynamicJos(cabinet)
      
    };
    reqs.onerror = function() {
      
    };
  }
}

async function initImagesBgroundSync() {
  if ('serviceWorker' in navigator && 'SyncManager' in window) {
    navigator.serviceWorker.ready
      .then(function(sw) {
        return sw.sync.register('sync-job-images-toggle');
      });
  }
}

$(document).on('change', '#search_job', function() {
  $('.search_options').addClass('is-hidden')
  $('.filter_options').addClass('is-hidden')
  $('.job-filter-button').addClass('is-hidden')
  if (this.value != ''){
    $('.filter_options').removeClass('is-hidden')
    $('.job-filter-button').removeClass('is-hidden')
    $('#'+this.value).removeClass('is-hidden')
  }
});

$(document).on('change', '#search_company', function() {
  $('.search_options').addClass('is-hidden')
  $('.filter_options').addClass('is-hidden')
  $('.job-filter-button').addClass('is-hidden')
  if (this.value != '') {
    $('.filter_options').removeClass('is-hidden')
    $('.job-filter-button').removeClass('is-hidden')
    $('#'+this.value).removeClass('is-hidden')
  }
});

$(document).on('click', '.doc-img-tab', function() {
  var a = $(this).text()

  $('.content-tab').attr('style', 'display:none')
  $('#'+a).attr('style', 'display:block')
  $('.content-tab').addClass('is-hidden')
  $('#'+a).removeClass('is-hidden');
  $('.doc-img-tab').removeClass('is-active');
  $(this).addClass('is-active')
});

$(document).on('click', '.upload_photo', function() {
  $('#upload_photo_modal').addClass('is-active')
});

$(document).on('change', '#job-photo-type', function() {
  var photoType = $('#table-job-image-type tr')
  for (var i = 0; i < photoType.length; ++i) {
    a = $('#table-job-image-type tr')[i]
    if (a.lastElementChild.textContent == 'Yes'){
      a.lastElementChild.textContent = 'No'
    }
  }

  if (this.value != ''){
    $('#table-job-image-type tr.' + this.value).children().last().text('Yes')
  }
});

$(document).on('click', '.show_photo', function() {
  $('#show_photo_').addClass('is-active')
  var selected_image = this.children[0].children[0].src
  var a = this.firstElementChild
  var src = a.childNodes[0].src

  var element = $('#image-full-size')[0]

  if (element != undefined){
    var collection_img = this.parentElement.parentElement.children
    for (var i = 0; i < collection_img.length; ++i) {

      var src = collection_img[i].firstElementChild.children[0].firstElementChild.src
      var img = document.createElement('img');
      var div = document.createElement('div');

      div.className = "mySlides fade"
      img.src = src
      element.appendChild(div).appendChild(img);
    }
    for (var i = 0; i < element.children.length; ++i) {
      var photo = element.children[i].children[0].src
      localStorage.slideIndex = i
      if(selected_image == photo){
        element.children[i].style.display = "block"
      }else{
        element.children[i].style.display = "none";
      }
    }
  }
  // element.children[0].style.display = "block"
});

$(document).on('click', '.next', function() {
  plusSlides(1, 'next')
});

$(document).on('click', '.prev', function() {
  plusSlides(-1, 'prev')
});

$(document).on('click', '.show_job_view_photo', function() {
  $('#show_photo').addClass('is-active')
  var selected_image = this.children[0].children[0].src
  var a = this.firstElementChild
  var src = a.childNodes[0].src

  var element = $('#image-full')[0]
  if (element != undefined){
    var collection_img = this.parentElement.parentElement.children
    for (var i = 0; i < collection_img.length; ++i) {

      var src = collection_img[i].firstElementChild.children[0].firstElementChild.src
      var img = document.createElement('img');
      var div = document.createElement('div');

      div.className = "mySlides fade"
      img.src = src
      element.appendChild(div).appendChild(img);
    }
  }
  for (var i = 0; i < element.children.length; ++i) {
    var photo = element.children[i].children[0].src
    localStorage.slideIndex = i
    if(selected_image == photo){
      element.children[i].style.display = "block"
    }else{
      element.children[i].style.display = "none";
    }
  }
});

$(document).on('click', '.delete-full-size', function() {
  var a = ''
  if ($('#image-full').length != 0) {
    a = $('#image-full')[0]
  }else {
    a = $("#image-full-size")[0]
  }
  if (a) {
    a.innerHTML = ''
    $(this).parents()[2].classList.remove('is-active')
  }
});

$(document).on('click', '.close-image-upload-modal', function() {
  closeModalAndRemoveForm()
});

$(document).on('click', '.btn-add-imag', function() {
  $('#modal-new-image-job').addClass('is-active')
});

function plusSlides(n, change_photo) {
  slideIndex = parseInt(localStorage.slideIndex)
  slideIndex += n
  localStorage.slideIndex = slideIndex
  showSlides(slideIndex, change_photo);
}


function showSlides(n, change_photo) {
  var i;

  slideIndex = parseInt(localStorage.slideIndex)

  var slides = document.getElementsByClassName("mySlides");

  if (n >= slides.length) {var slideIndex = 1}
  if (n < 1) {var slideIndex = slides.length}
  for (i = 0; i < slides.length; i++) {
      // slides[i].style.display = "none";
    if (change_photo == 'prev'){
      if(slides[i].style.display == "block"){
        slides[i-1].style.display = "block";
        slides[i].style.display = "none";
        break;
      }
    }else {
      if(slides[i].style.display == "block"){
        slides[i+1].style.display = "block";
        slides[i].style.display = "none";
        break;
      }
    }
  }

  if (localStorage.slideIndex >= slides.length){
    localStorage.slideIndex = slides.length -1
  }

  if (localStorage.slideIndex < 0){
    localStorage.slideIndex = 0
  }
}

var zoom = 1;

// $(document).on('click', '.zoom-photo', function() {
//   zoom += 0.1;
//   $('.fade').css('transform', 'scale(' + zoom + ')');
// });
$(document).on('click', '.zoom-init-photo', function() {
  zoom = 1;
  var imgContain = get_image()
  $(imgContain).css('transform', 'scale(' + zoom + ')');
});
// $(document).on('click', '.zoom-out-photo', function() {
//   zoom -= 0.1;
//   $('.fade').css('transform', 'scale(' + zoom + ')');
// });


$(document).on('click','.zoom-photo',function () {
  zoom += 0.1;
  var imgContain = get_image()
  $(imgContain).css('transform', 'scale(' + zoom + ')')
});

$(document).on('click','.zoom-out-photo',function () {
  zoom -= 0.1;
  var imgContain = get_image()
  $(imgContain).css('transform', 'scale(' + zoom + ')');
});

function get_image() {
  var total = $('.fade')
  var image
  for (var i = 0; i < total.length; ++i) {
    if (total[i].style.display == "block") {
      image = total[i].firstElementChild
      break;
    }
  }
  return image;
}

$(document).on('change', '#user_role_id', function() {
  if (this.value == '5'){
    $('.service_access').parent().removeClass('is-hidden')
  }else{
    $('.service_access').parent().addClass('is-hidden')
  }
});

$(document).on('click', '#edit_technician', function() {
  $('.select_tech').removeClass('is-hidden');
  $('.cancel_change').removeClass('is-hidden')
  $('.tech_list').addClass('is-hidden');
  $('.edit_tech').addClass('is-hidden');
});

$(document).on('click', '#edit_working_days', function() {
  $('.working-days-wrapper').removeClass('is-hidden');
  $('.operating_hours').removeClass('is-hidden');
  $(this).addClass('is-hidden');
  $('.operating_hours').datepicker({
    multidate: true,
    format: 'dd/mm/yyyy',
    todayBtn: 'linked',
    todayHighlight: true
  })
});

$(document).on('change', '#job-file-type', function() {
  $('.job_file').removeClass('is-hidden')
  $('.job_file').addClass('is-hidden')
  $('#'+this.value).removeClass('is-hidden')
});

$(document).on('change', '.new-job-file', function() {
  var input = event.target;
  var children = "";

  if ($('#table-job-files > tbody:last > tr').text() == 'No files selected') {
    $('#table-job-files > tbody:last').html('');
  }

  for (var i = 0; i < input.files.length; ++i) {
    $('#table-job-files > tbody:last').append('<tr><td class="selected_file">'+ input.files[i].name +'</td></tr>');

  }
});

$(document).on('change', '.operating_hours', function() {
  a = $('.operating_hours').val().split(',')
  $("#working_days").datepicker('setDate', a)
  $('.text_working_days').text(a[0] + " - " + a[a.length -1])
  $('#table-working-days > tbody:last').html('');
  var dates = a

  for (var i = 0; i < dates.length; ++i) {
    $('#table-working-days > tbody:last').append('<tr><td class="table_width"><input type="text" name="show_working_days" id="working_days" value="'+ dates[i] +'" class="input" autocomplete="off" disabled></td><td class="table_width"><div class="columns hr_min_width"><div><input type="time" name="s_hours_minutes['+ dates[i] +']" id="hours" value="" placeholder="01:00" autocomplete="off"></div></div></div></td><td class="table_width"><div class="columns hr_min_width"><div><input type="time" name="e_hours_minutes['+ dates[i] +']" id="hours" value="" placeholder="01:00" autocomplete="off"></div></div></td></tr>')
  }
});

$(document).on('change', '#job_delivery_partner_id_', function(event) {
  var id = this.value
  $('#job_manager_id_');

  $.ajax({
    type: "GET",
    url: '/companies/' + id + '/fetch_delivery_partner_project_manager',
    dataType: 'json'
  }).done(function(data) {
    jQuery.each(data, function(key, value) {
      return $('#job_manager_id_').append($('<option></option>').attr('value', value[0]).text(value[1]));
    });
  });
});

$(document).on('change', '#job_delivery_partner_id_', function(event) {
  var id = this.value
  $('#job_team_job_user_ids_');

  $.ajax({
    type: "GET",
    url: '/companies/' + id + '/fetch_delivery_partner_user',
    dataType: 'json'
  }).done(function(data) {
    jQuery.each(data, function(key, value) {
      return $('#job_team_job_user_ids_').append($('<option></option>').attr('value', value[0]).text(value[1]));
    });
  });
});

$(document).on('click', '.plan-docs', function() {
  $('#company_project_modal').addClass('is-active')
});

$(document).on('click', '.project-docs', function() {
  $('#company_project_manager').addClass('is-active')
});

$(document).on('click', '.view-plan', function() {
  $('#project_manager_project').addClass('is-active')
});

document.addEventListener('turbolinks:load', () => {
  $( "#cal-action-dailog" ).dialog({ autoOpen: false, closeText: '' });
  $(document).on("click",".tui-full-calendar-weekday-schedule",function() {
    var jobId = $(this).data("schedule-id")
    $(".dailog-edit-btn")[0].href = `/admin/jobs/${jobId}/edit`;
    $(".dailog-show-btn")[0].href = `/admin/jobs/${jobId}`;
    $(".dailog-detail-btn")[0].href = `/jobs/${jobId}/edit_schedule`;

    $( "#cal-action-dailog" ).dialog( "open" );
    $(document).on("click",".cal-action-btn",function() {
      $( "#cal-action-dailog" ).dialog("close");
    });
    $( "#cal-action-dailog" ).dialog({
      position: { my: "center bottom", at: "center top", of: this }
    });
  });

  $(".by_project_status").on('change', function() {
    showSelectedStatusOption(this.value);
  })

  showSelectedStatusOption('in_progress');
});

$(document).on('click', '.save-preference', function() {
  var jobId = $('#job_id').val()
  var field =  this.getAttribute('data-view-mode')
  $.ajax({
    type: 'PATCH',
    url: `/jobs/${jobId}/save_user_preference`,
    data: {
      select_mode: field
    }
  });
});

document.addEventListener('turbolinks:load', () => {
  $( "#cal-action-dailog" ).dialog({ autoOpen: false, closeText: '' });
  $(document).on("click",".tui-full-calendar-month-more-schedule",function() {
    var jobId = $(this).data("schedule-id")
    $(".dailog-edit-btn")[0].href = `/admin/jobs/${jobId}/edit`;
    $(".dailog-show-btn")[0].href = `/admin/jobs/${jobId}`;
    $(".dailog-detail-btn")[0].href = `/jobs/${jobId}/edit_schedule`;
    $( "#cal-action-dailog" ).dialog( "open" );
    $(document).on("click",".cal-action-btn",function() {
      $( "#cal-action-dailog" ).dialog("close");
    });
    $( "#cal-action-dailog" ).dialog({
      position: { my: "center bottom", at: "center top", of: this }
    });
  });

  $('#query').selectize({
    maxItems: 1,
    persist: true,
    refreshOptions: true,
    openOnFocus: false,
    create: true,
    hideSelected: true,
    valueField: 'name',
    labelField: 'name',
    searchField: ['name', 'po_number', 'city'],
    load: function(query, callback) {
      
      $.ajax({
        url: "/admin/jobs/autocomplete",
        data: { query: query},
        dataType: "json",
        type: 'GET',
        error: function(res){
          callback();
        },
        success: function(res) {
          callback(res.slice(0,10));
        }
      })
    },
    onChange: function(query) {
      getJobByName(query)
    },
    render: {
      option: function(item, escape) {
        return `<div class="selectize-custom-options">` + escape(item.name) + `</div>`
      }
    }
  });

  function getJobByName(query) {
    $.ajax({
      url: `/admin/jobs/search?query=${query}`,
      type: 'GET',
      dataType: 'script'
    })
  }
});

function showSelectedStatusOption(status) {
  $(".by_job_dropdown").children('option:gt(0)').hide();
  $(".by_job_dropdown").children("option[data-status^="+status+"]").show();
  $(".by_job_dropdown").prop('selectedIndex',0);
}
