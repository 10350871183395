$(document).on('turbolinks:load', () => {
  // Below if statements are used becasue select2 is initializing twice
  // when we navigate through the browser forward and back button
  if ($('#job_customer_').hasClass("select2-hidden-accessible")) {
    $("#job_customer_").select2('remove');
  }

  if ($('#job_manager_id_').hasClass("select2-hidden-accessible")) {
    $("#job_manager_id_").select2('remove');
  }

  if ($('#job_lead_id_').hasClass("select2-hidden-accessible")) {
    $("#job_lead_id_").select2('remove');
  }

  if ($('#job_team_job_user_ids_').hasClass("select2-hidden-accessible")) {
    $("#job_team_job_user_ids_").select2('remove');
  }

  if ($('#job_team_ids').hasClass("select2-hidden-accessible")) {
    $("#job_team_ids").select2('remove');
  }

  
  $('#job_team_ids, .accomplishments').select2({
    width: '100%'
  });

  $('#job_team_job_user_ids_').select2({
    width: '100%'
  });

  $('#user_airport_id').select2({
    width: '100%',
    ajax: {
      url: '/airports',
      dataType: 'json'
    },
    delay: 250,
    cache: true,
    minimumInputLength: 1
  });

  $(document).on('show', '#team-modal', function() {
    $('#team_team_members_attributes_0_user_id').select2({
      width: '100%'
    });
  });

  $('#service_access_').select2({
    width: '100%'
  });

  $('#job_manager_id_').select2({
    width: '100%'
  });

  $('#job_lead_id_').select2({
    width: '100%'
  });

  $('#job_customer_').select2({
    width: '100%'
  });
  
  $('#job_delivery_partner_id_').select2({
    width: '100%'
  });

  $('.options-select-cable-state').select2({
    width: '100%'
  });
});
