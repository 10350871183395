// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("@nathanvda/cocoon")
require("selectize/dist/js/standalone/selectize")

import 'stylesheets/application';
// IMPORTED HERE DUE TO ERROR OF IMAGE PATH NOT RESOLVED.
import '@fortawesome/fontawesome-free/css/all.css';
import * as _ from "lodash";
import './main';              // PACKAGES LIBRARY
import 'cable/custom';        // CUSTOM APPLICATION PAGE SPECIFIC JS.
import './app';               // GLOBAL CUSTOM JS.
import './location_detail';               // GLOBAL CUSTOM JS.
import * as d3 from 'd3';
import 'bulma-pageloader/dist/css/bulma-pageloader.min.css';
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

import toastr from 'toastr';
window.toastr = toastr;

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// CUSTOM JAVASCRIPT
window.d3 = d3;

function toggleButton(el) {
  if (el == null) { return; };
  // Get the target from the 'data-target' attribute
  const table = document.getElementById(el.dataset.target);

  // If the table is hidden
  if (table.classList.contains('is-hidden')) {
    // Mark the button as open (we'll toggle it after this block)
    el.childNodes[0].classList = 'fas fa-lg fa-angle-up';
    localStorage.setItem(el.id, true);
  } else {
    el.childNodes[0].classList = 'fas fa-lg fa-angle-down';
    localStorage.setItem(el.id, false);
  }
  table.classList.toggle('is-hidden');
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    //returns installed service workers
    if (registrations.length) {
      for(let registration of registrations) {
        registration.unregister();
      }
    }
  });
}

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js', { scope: './' })
    .then(function(reg) {
      
    });
}

// Adapted from
// http://blog.graykemmey.com/2020/07/07/pagination-and-scroll-restoration-with-turbolinks/

Turbolinks.savedScrolls = {}
Turbolinks.savedToggles = {}
Turbolinks.savedSearch = ''

document.addEventListener("turbolinks:before-visit", function(event) {
  console.time("turbolinks:before-visit");
  // Save scroll state
  Turbolinks.savedScrolls[window.location.href] = {
    document:document.documentElement.scrollTop,
    body: document.body.scrollTop
  }

  Object.keys(localStorage).forEach((key) => {
    // Save toggle state
    if (key.indexOf('toggle') > -1) {
        Turbolinks.savedToggles[key] = localStorage.getItem(key);
    // Save search state
    } else if (key.indexOf('search') > -1) {
        Turbolinks.savedSearch = localStorage.getItem(key);
    }
  });
  console.timeEnd("turbolinks:before-visit");
});

document.addEventListener("turbolinks:render", function(event) {
  console.time("turbolinks:render");
  const savedToggle = Turbolinks.savedToggles;

  // if (!savedToggle) { return; }
  if (savedToggle) {
    // delete(Turbolinks.savedToggles);

    Object.keys(savedToggle).forEach((key) => {
      if (key.indexOf('toggle') > -1) {
        var button = document.getElementById(key);

        if (savedToggle[key] == "true") {
          toggleButton(button);
        }
      }
    });
  }

  const savedScroll = Turbolinks.savedScrolls[window.location.href];

  // if (!savedScroll) { return; }
  if (savedScroll) {
    // delete(Turbolinks.savedScrolls[window.location.href])

    if (savedScroll.document != null) {
      if (savedScroll.document < document.documentElement.scrollHeight) {
        document.documentElement.scrollTop = savedScroll.document
      }
    }

    if (savedScroll.document != null) {
      if (savedScroll.body < document.body.scrollHeight) {
        document.body.scrollTop = savedScroll.body
      }
    }
  }
});

require("trix")
require("@rails/actiontext")

// Generic function to remove alert
$(document).on('click', '.alert-delete-btn', function() {
  $('.notification').addClass('is-hidden');
});